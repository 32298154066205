.content-loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ion-image-loading {
    width: 20rem;
    height: 6.2rem;
    opacity: 0.8;
}

.ion-text-loading{
    color: var(--ion-color-step-600);
    font-size: 1rem;
    margin-bottom: 0.8rem;
}

ion-spinner {
    transform: scale(1.5);
    color: var(--ion-color-step-600);
}


@media (min-width: 2560px){
    ion-spinner{
        transform: scale(2.5);

    }
    .ion-image-loading {
        width: 28rem;
        height: 10rem;
    }
    
    .ion-text-loading{
        color: var(--ion-color-step-600);
        font-size: 2rem;
        margin-bottom: 1.5rem;
    }
}








