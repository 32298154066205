/* Principal */

.ABMClientsTable {
    border-radius: 10px;
    background: var(--ion-color-primary);
}

.ABMClientsCardTable {
    background: var(--ion-color-light);
    border-radius: 0.625rem;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

.ABMClientsGrid {
    box-shadow: 0px 2px 2px 0px;
    border-radius: 10px;
}

.ABMClientsGrid ion-row {
    border-bottom: 1px solid var(--ion-color-step-500);
}

.ABMClientsCol {
    text-align: center;
    color: var(--ion-text-color);
}

.ABMClientsColTitle {
    font-weight: bold;
}

.justify-abm {
    justify-content: flex-end !important;
}

/* ABM CLIENTS TABLE */

.ABMClientsTableButton {
    zoom: 1.2;
}

.ABMClientsTableImage {
    width: 75%;
    margin: auto;
    padding: 5px;
}

.ABMClientsTableToggle {
    opacity: revert;
    zoom: 0.8;
}

/* ADD ABM CLIENTS */
.ABMClientsFormCard {
    width: 80%;
    margin: auto;
    margin-bottom: 15px;
    ;
}

.ABMClientsFormTitle {
    color: var(--ion-text-color);
    margin-top: 20px;
}

.ABMClientsFormCol {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.ABMClientsFormLabel {
    color: var(--ion-color-step-950);
    font-weight: bold;
}

.ABMClientsFormItem {
    border: 1px solid #b7bec2;
    border-radius: 15px;
    padding: 0px !important;
}

.ABMClientsFormInput {
    font-size: 14px;
    border-radius: 4px;
    color: var(--ion-color-step-950);
}

.AMBClientsButton {
    font-size: 300%;
}


.customSelectClients .popover-content {
    overflow: hidden !important;
}

.radio-checked {
    --color-checked: var(--cust-color-decorator);
}

input[type=file]::-webkit-file-upload-button {
    background-color: transparent;
    border: solid;
    border-radius: 12px;
    color: var(--cust-button-color);
    border-color: var(--cust-button-color);
    padding: 8px 15px;
    text-transform: uppercase;
    font-weight: bold;
}

input[type=file]::-webkit-file-upload-button:hover {
    background-color: var(--cust-button-color);
    color: white;
    cursor: pointer;
}

@media (min-width: 1920px) {
    .ABMClientsTableImage{
        width: 45%;
    }

    .ABMClientsCol{
        font-size: 1rem;
    }

    .content-abm-client{
        margin-top: 2rem !important;
    }

    .ABMClientsFormCard{
        margin-top: 1.8rem;
    }
}

@media (min-width: 2560px) {
    .ABMClientsCol{
        font-size: 1.3rem;
    }

    .ABMClientsTableButtonContainer{
        height: 6rem;
    }
    
    .ABMClientsTableButton{
        font-size: 1.8rem;
    }
}

@media (min-width: 3840px) {

    .ABMClientsTableImage{
        width: 30%;
    }

    .container-button-add{
        height: 5rem;
    }

    .container-button-add * {
        zoom: 1.6 !important;
    }

    .ABMClientsCol{
        font-size: 1.8rem;
    }
    .ABMClientsTableToggle{
        zoom: 1.5
    }
    .ABMClientsFormTitle{
        font-size: 2rem;
    }

    .ABMClientsTableButtonContainer{
        height: 8rem;
    }
    
    .ABMClientsTableButton{
        font-size: 2.5rem;
    }

    .ABMClientsFormCard{
        margin-top: 3rem;
    }

    .ABMClientsFormLabel{
        font-size: 1.5rem;
    }

    .ABMClientsFormInput{
        font-size: 1.4rem;
    }

    .container-buttons{
        height: 7rem;
    }

    .container-button{
        height: auto;
    }
    .AMBClientsButton{
        font-size: 3.5rem;
    }

    .ABMClientsModalText{
        font-size: 2.2rem;
    }
}

@media (max-width: 1020px){

    .ABMClientsTableImage{
        width: 40%;
    }

    .ABMClientsTableToggle{
        zoom: 0.6
    }

    .ABMClientsCol{
        font-size: 0.8rem;
    }

    .ABMClientsTableButtonContainer{
        height: 4rem;
    }
    
    .ABMClientsTableButton{
        font-size: 1rem;
    }

    .col-hidden{
        display: none;
    }
}


@media(max-width: 640px) {

    .mobile-hidden {
        display: none;
    }

    .justify-abm {
        justify-content: center !important;
    }

    .ABMClientsFormCard {
        width: 95%;
    }

    .ABMClientsFormInput {
        margin-left: 5%;
    }

}

@media(max-width: 280px) {

    .mobile-hidden-280 {
        display: none;
    }

}