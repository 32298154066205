.colLogin {
  background-color: var(--cust-login-back-color);
  color: #ffffff;
  height: 10em;
  margin: 0px;
}

.colLoginForgot {
  height: 12em !important;
}

.login-title {
  font-size: 30px;
  font-weight: bold;
}

.login-title.ios {
  padding-bottom: 60px;
}

.cardLogin {
  border-radius: 10px;
  position: relative;
  top: -100px;
  max-width: 40%;
}

.login-image {
  width: 40%;
  margin: 0 auto;
}

.login-card-title {
  margin: 1rem;
  font-size: 3rem;
  font-weight: bold;
  color: var(--cust-login-welcome-color);
}

.login-item {
  border: 1px solid #b7bec2;
  border-radius: 15px;
  padding: 0px !important;
}

.inputLogin {
  font-size: 14px;
}

.loginEye {
  color: var(--ion-color-secondary);
  font-size: 16px;
}

.login-button {
  --border-radius: 12px;
  color: var(--cust-button-color);
  --border-color: var(--cust-button-color);
}

.login-button:hover {
  border-radius: 12px;
  border-color: var(--cust-button-color);
  background-color: var(--cust-button-color);
  color: white;
}

.login-link {
  color: var(--cust-login-welcome-color);
}

.login-invalid {
  font-size: 14px !important;
  color: white;
  background-color: #e63558;
  padding-left: 15px;
  border-radius: 10px;
  padding: 5px 15px;
  margin-top: 15px !important;
}

.login-valid-text {
  font-size: 10px !important;
  color: var(--cust-valid-error-color);
  padding-left: 15px;
  text-align: start;
}

.login-back-arrow {
  font-size: 30px;
  color: white;
  position: relative;
  top: 5px;
  left: -47%;
}

.login-modal {
  --max-width: 300px;
  --max-height: 200px;
  --border-radius: 10px;
  --background: var(--cust-modal-color-rgb);
}

.login-modal-text {
  color: var(--ion-color-medium-contrast);
}

@media (max-width: 768px) {

  .login-title {
    font-size: 24px;
    word-wrap: normal !important;
  }

  .cardLogin {
    max-width: 90%;
    padding-bottom: 0;
  }

  .login-image {
    width: 60%;
  }

  .login-back-arrow {
    left: -43%;
  }

  .inputLogin {
    padding-left: 10px !important;
  }
}