/* :root {
    --cust-ticks-size: 6;
} */

/* Internos Cards */

.intern-header {
    background: var(--cust-card-header-alerts-color);
    color: var(--ion-text-color, #000) !important;
    font-size: 2.2vh;
    padding: 0.5%;
    font-weight: 600;
}

.intern-icon {
    color: var(--ion-color-success);
    padding: 0px;
    margin-right: 0.5%;
    font-size: 3vh;
}

.intern-icon-alerted {
    color: var(--cust-color-alerted);
    padding: 0px;
    margin-right: 0.5%;
    font-size: 3vh;
}

.intern-icon-undefined{
    color: var(--ion-color-step-750);
    padding: 0px;
    margin-right: 0.5%;
    font-size: 3vh;
}

.intern-group {
    text-align: center !important;
    font-size: 2.2vh;
    color: var(--ion-text-color);
    margin: 0;
    padding: 0;
}

.intern-totals{
    color: var(--ion-text-color, #000) ;
    justify-content: center;
    font-size: 2.5vh;
}

.intern-approved {
    padding-top: 5%;
    font-size: 2.5vh;
    padding-bottom: 5%;
    color: var(--ion-text-color, #000) !important;
}

.intern-refresh {
    padding-top: 2%;
    font-size: 2vh;
    padding-bottom: 2%;
    color: var(--ion-color-step-550, #737373);
}

.intern-subtitulos {
    font-weight: 600;
}

.intern-row-card {
    margin-left: 10%;
    margin-right: 10%;
    border-bottom: 1px var(--ion-color-step-550, #737373) solid;
}

.displayGraphics-icon {
    position: absolute;
    right: 1%;
    font-size: 3vh;
    padding: 0;
}

.intern-content-card {
    display: contents;
}

/* Internos Percentage */

.intern-percentage {
    display: flex;
    justify-content: center;
}

.intern-percentage-text {
    font-size: 3.5vh;
}

/* Internos Gráficos */

.graph-intern {
    margin: 0px;
    position: relative;
    border-radius: 10px;
    background: var(--ion-color-light);
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

.graph-intern-reports{
    margin: 0px;
    position: relative;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

.graph-intern-header {
    padding-top: 5px;
    padding-bottom: 0px;
}

.graph-intern-header .download-button {
    width: fit-content;
    position: absolute;
    right: 10px;
    top: 5px;
    cursor: pointer;
}

.graph-intern-content {
    padding: 0 !important;
}

.graph-content-lineReverse {
    padding: 0em;
}

.graph-content-combinedCoelsaReverse {
    padding: 1em;
}

.graph-content-jpos{
    padding: 1em;
}

.graph-content-lifespan{
    padding: 1em;
}

.graph-content-evolution {
    padding: 0;
    height: 16rem;
}

.graph-intern-title {
    color: var(--ion-text-color, #000) !important;
    font-size: 1.6vh;
    font-weight: 600;
    min-height: 3em;
}

.graph-intern-icon {
    font-size: 2vh;
    color: var(--ion-color-secondary);
}

@media (min-width: 1920px) {

    .intern-header {
        font-size: 1.8vh;
    }

    .intern-icon, .intern-icon-alerted, .intern-icon-undefined {
        font-size: 2.5vh;
    }

    .intern-approved {
        padding: 0;
        font-size: 1.9vh;
        padding-bottom: 3%;
    }

    .intern-totals{
        font-size: 1.9vh;
    }

    .intern-refresh {
        padding-top: 0;
        font-size: 1.7vh;
    }

    .intern-percentage-text {
        font-size: 2.5vh;
    }

    .intern-row-card {
        margin-left: 5%;
        margin-right: 5%;
    }

    .intern-group {
        font-size: 1.9vh;
    }

    .graph-intern-title {
        font-size: 1.3vh;
    }
}

@media (max-width: 992px) {

    .intern-header {
        font-size: 1.7vh;
    }

    .intern-icon, .intern-icon-alerted, .intern-icon-undefined {
        font-size: 2.5vh;
    }

    .intern-approved {
        padding: 0;
        font-size: 1.8vh;
        text-transform: none;
    }

    .intern-totals{
        font-size: 1.8vh;
    }

    .intern-refresh {
        padding: 0;
        font-size: 1.6vh;
        text-transform: none;
    }

    .intern-percentage-text {
        font-size: 2.5vh;
    }

    .intern-row-card {
        margin-left: 5%;
        margin-right: 5%;
    }

    .intern-group {
        font-size: 1.8vh;
        margin-top: 1%;
        margin-bottom: 1%;
    }

    .graph-intern-icon {
        display: none;
    }

    .graph-intern-title {
        font-size: 1vh;
    }

}

@media(max-width: 520px) {

    .intern-header {
        font-size: 1.5vh;
    }

    .intern-icon, .intern-icon-alerted, .intern-icon-undefined {
        font-size: 2vh;
    }

    .intern-approved {
        font-size: 2vh;
        padding-top: 0;
        padding-bottom: 1%;
    }

    .intern-totals{
        font-size: 2vh;
    }

    .intern-refresh {
        padding-top: 0;
        font-size: 1.8vh;
    }

    .intern-percentage-text {
        font-size: 3vh;
    }

    .intern-group {
        font-size: 1.8vh;
    }


    .graph-intern-title {
        font-size: 1.3vh;
    }

}