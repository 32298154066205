.item-inner {
  display: none;
}

.card-container-title {
  font-size: 1rem;
  color: var(--ion-text-color);
}

.clientMaintenance-status-icon {
  font-size: 2.5vh;
  position: absolute;
  /* margin-top: 0.5%; */
}

.client-card-content-alignment {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.col-client-card-container {
  border-radius: 1.5rem;
}

.toggle-size-maintenance {
  width: 2.5rem;
  height: 25px;
  --handle-width: 1.5rem
}

.cards-client-container-header {
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--cust-card-header-color);
}

.client-card {
  position: relative;
  border-radius: 0.625rem;
  background: var(--ion-color-light);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  padding-top: 0;
  margin: 0;
  width: 100%;
}

.client-card-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 2.5rem;
  padding: 0;
  background: var(--cust-card-header-color);
  text-align: center;
}

.client-card-title {
  font-size: 0.8rem;
  font-weight: 700;
  width: 100%;
  align-self: center;
  padding: 0 10%;
  margin: 0;
}

.client-card-content-alignment {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.client-card-content-title {
  font-size: 0.8rem;
}

.card-status-icon-not-checked {
  color: var(--ion-color-tertiary-contrast);
}

.card-status-icon-checked {
  color: var(--ion-color-secondary);
}

.conitaner-cards-client-maintenance {
  display: grid;
  gap: 0.8rem;
}


@media (min-width: 2560px) {
  .col-client-card-container {
    margin-top: 2.8rem !important;
  }

  .conitaner-cards-client-maintenance {
    grid-template-columns: repeat(5, 1fr);
  }

  .clientMaintenance-status-icon {
    font-size: 2vh;
    margin: 0.2rem;
  }

  .client-card-title {
    font-size: 1.2rem;
  }

  .card-container-title {
    font-size: 1.8rem;
  }

  .client-card-content-title {
    font-size: 1.2rem;
  }

  .client-card {
    height: 10rem;
  }

  .cards-client-container-header {
    height: 2.6rem;
  }

  .toggle-size-maintenance {
    width: 4rem;
    height: 2.6rem;
    --handle-width: 2.2rem;
    --handle-height: 2rem;
    --handle-spacing: 0.2rem;
    border-radius: 45px;
  }

}

@media (min-width: 3840px) {
  .col-client-card-container {
    margin-top: 3.8rem !important;
  }

  .clientMaintenance-status-icon {
    font-size: 1.5vh;
    margin: 0.2rem;
  }

  .client-card-title {
    font-size: 1.4rem;
  }

  .card-container-title {
    font-size: 2rem;
  }

  .client-card-content-title {
    font-size: 1.4rem;
  }

  .client-card {
    height: 12rem;
  }

  .cards-client-container-header {
    height: 2.8rem;
  }

  .toggle-size-maintenance {
    width: 4.6rem;
    height: 3.2rem;
    --handle-width: 2.6rem;
    --handle-height: 2.5rem;
    --handle-spacing: 0.3rem;
    border-radius: 45px;
  }
}



@media (max-width: 1920px) {
  .col-client-card-container {
    margin-top: 2.8rem !important;
  }

  .conitaner-cards-client-maintenance {
    grid-template-columns: repeat(4, 1fr);
  }

  .clientMaintenance-status-icon {
    font-size: 2.3vh;
    top: 0.5rem;
    left: 0.5rem;
  }
}

@media (max-width: 1366px) {

  .client-card-content-title {
    text-align: center;
  }

  .col-client-card-container {
    margin-top: 1.8rem !important;
  }


  .clientMaintenance-status-icon {
    font-size: 2.8vh;
    top: 0.6rem;
    left: 0.6rem;
  }

}

@media (max-width: 820px) {

  .client-card-content-title {
    text-align: center;
  }

  .clientMaintenance-status-icon {
    font-size: 2.3vh;
    top: 0.5rem;
    left: 0.6rem;
  }

}

@media (max-width: 620px) {
  .clientMaintenance-status-icon {
    font-size: 2.2vh;
    margin-top: 0.2rem;
    top: 0.4rem;
  }

  .conitaner-cards-client-maintenance {
    grid-template-columns: repeat(2, 1fr);
  }

}