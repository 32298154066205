/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** Custom variables **/

  /* Client color decoration */
  --cust-color-decorator: #29b2b7;
  --cust-color-decorator-rgb: 41, 178, 183;

  /*Login colors*/

  /* Graphs line colors */

  --cust-color-percentage: #20babb;
  --cust-color-percentage-rgb: 32, 186, 187;

  --cust-color-quantity: #3bca2b;
  --cust-color-quantity-rgb: 59, 202, 43;

  --cust-color-umbral: #fbc804;
  --cust-color-umbral-rgb: 254, 218, 0;

  --cust-color-alerted: #e73557;
  --cust-color-alerted-rgb: 231, 53, 87;

  --custom-color-coelsa: #ff8b20;
  --custom-color-coelsa-rgb: 255, 139, 32; 

  --custom-color-coelsa-bordo: #5f0046;
  --custom-color-coelsa-bordo-rgb: 95, 0, 70;

  --custom-color-avg-time-1: #A9A9A9;
  --custom-color-avg-time-1-rgb: 169, 169, 169;
  
}

/** Ionic CSS Variables LIGHT**/
body.light {
  --ion-color-primary: #f6f6f6;
  --ion-color-primary-rgb: 246, 246, 246;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #d8d8db;
  --ion-color-primary-tint: #f6f7fa;

  --ion-color-secondary: #29b2b7;
  --ion-color-secondary-rgb: 41, 178, 183;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #249da1;
  --ion-color-secondary-tint: #3ebabe;

  --ion-color-tertiary: #e63558;
  --ion-color-tertiary-rgb: 230, 53, 88;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #ca2f4d;
  --ion-color-tertiary-tint: #e94969;

  --ion-color-success: #16bc6b;
  --ion-color-success-rgb: 22, 188, 107;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #13a55e;
  --ion-color-success-tint: #2dc37a;

  --ion-color-warning: #f6b500;
  --ion-color-warning-rgb: 246, 181, 0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #d89f00;
  --ion-color-warning-tint: #f7bc1a;

  --ion-color-danger: #ca022d;
  --ion-color-danger-rgb: 202, 2, 45;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #b20228;
  --ion-color-danger-tint: #cf1b42;

  --ion-color-dark: #22397b;
  --ion-color-dark-rgb: 34, 57, 123;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e326c;
  --ion-color-dark-tint: #384d88;

  --ion-color-medium: #b7bec2;
  --ion-color-medium-rgb: 183, 190, 194;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #a1a7ab;
  --ion-color-medium-tint: #bec5c8;

  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #e0e0e0;
  --ion-color-light-tint: #ffffff;

  --cust-body-color: var(--ion-color-medium);
  --cust-card-header-color: #c1c2c5;
  --cust-card-header-alerts-color: #dddfe0;
  --cust-button-color: var(--ion-color-tertiary);
  --cust-modal-color-rgb: rgb(255, 255, 255);
  --cust-valid-error-color: #cf1b42;
  --cust-login-welcome-color: var(--ion-color-dark);
  --cust-login-back-color: var(--ion-color-secondary);
  --cust-scroll-icon-color:  var(--ion-color-);
}

.ios body.light {
  --ion-background-color: #f6f6f6;
  --ion-background-color-rgb: 246, 246, 246;

  --ion-text-color: #252525;
  --ion-text-color-rgb: 37, 37, 37;

  --ion-color-step-50: #ececec;
  --ion-color-step-100: #e1e1e1;
  --ion-color-step-150: #d7d7d7;
  --ion-color-step-200: #cccccc;
  --ion-color-step-250: #c2c2c2;
  --ion-color-step-300: #b7b7b7;
  --ion-color-step-350: #adadad;
  --ion-color-step-400: #a2a2a2;
  --ion-color-step-450: #989898;
  --ion-color-step-500: #8e8e8e;
  --ion-color-step-550: #838383;
  --ion-color-step-600: #797979;
  --ion-color-step-650: #6e6e6e;
  --ion-color-step-700: #646464;
  --ion-color-step-750: #595959;
  --ion-color-step-800: #4f4f4f;
  --ion-color-step-850: #444444;
  --ion-color-step-900: #3a3a3a;
  --ion-color-step-950: #2f2f2f;
}

.md body.light {
  --ion-background-color: #f6f6f6;
  --ion-background-color-rgb: 246, 246, 246;

  --ion-text-color: #252525;
  --ion-text-color-rgb: 37, 37, 37;

  --ion-color-step-50: #ececec;
  --ion-color-step-100: #e1e1e1;
  --ion-color-step-150: #d7d7d7;
  --ion-color-step-200: #cccccc;
  --ion-color-step-250: #c2c2c2;
  --ion-color-step-300: #b7b7b7;
  --ion-color-step-350: #adadad;
  --ion-color-step-400: #a2a2a2;
  --ion-color-step-450: #989898;
  --ion-color-step-500: #8e8e8e;
  --ion-color-step-550: #838383;
  --ion-color-step-600: #797979;
  --ion-color-step-650: #6e6e6e;
  --ion-color-step-700: #646464;
  --ion-color-step-750: #595959;
  --ion-color-step-800: #4f4f4f;
  --ion-color-step-850: #444444;
  --ion-color-step-900: #3a3a3a;
  --ion-color-step-950: #2f2f2f;
}

/** Ionic CSS Variables DARK**/
body.dark {
  --ion-color-primary: #272c33;
  --ion-color-primary-rgb: 39, 44, 51;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #22272d;
  --ion-color-primary-tint: #3d4147;

  --ion-color-secondary: #29b2b7;
  --ion-color-secondary-rgb: 41, 178, 183;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #249da1;
  --ion-color-secondary-tint: #3ebabe;

  --ion-color-tertiary: #e63558;
  --ion-color-tertiary-rgb: 230, 53, 88;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #ca2f4d;
  --ion-color-tertiary-tint: #e94969;

  --ion-color-success: #16bc6b;
  --ion-color-success-rgb: 22, 188, 107;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #13a55e;
  --ion-color-success-tint: #2dc37a;

  --ion-color-warning: #f6b500;
  --ion-color-warning-rgb: 246, 181, 0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #d89f00;
  --ion-color-warning-tint: #f7bc1a;

  --ion-color-danger: #ca022d;
  --ion-color-danger-rgb: 202, 2, 45;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #b20228;
  --ion-color-danger-tint: #cf1b42;

  --ion-color-dark: #22397b;
  --ion-color-dark-rgb: 34, 57, 123;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e326c;
  --ion-color-dark-tint: #384d88;

  --ion-color-medium: #272b34;
  --ion-color-medium-rgb: 39, 43, 52;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #22262e;
  --ion-color-medium-tint: #3d4048;

  --ion-color-light: #323743;
  --ion-color-light-rgb: 50, 55, 67;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #2c303b;
  --ion-color-light-tint: #474b56;

  --cust-body-color: var(--ion-color-light);
  --cust-card-header-color: #212121;
  --cust-card-header-alerts-color: #212121;
  --cust-button-color: var(--ion-color-warning);
  --cust-modal-color-rgb: rgb(39, 43, 52);
  --cust-valid-error-color: var(--ion-color-warning);
  --cust-login-welcome-color: var(--ion-color-light-contrast);
  --cust-login-back-color: #008e8f;
  --cust-scroll-icon-color:  var(--ion-text-color);
}

.ios body.dark {
  --ion-background-color: #272b34;
  --ion-background-color-rgb: 39, 43, 52;

  --ion-text-color: #f5f6f9;
  --ion-text-color-rgb: 245, 246, 249;

  --ion-color-step-50: #31353e;
  --ion-color-step-100: #3c3f48;
  --ion-color-step-150: #464952;
  --ion-color-step-200: #50545b;
  --ion-color-step-250: #5b5e65;
  --ion-color-step-300: #65686f;
  --ion-color-step-350: #6f7279;
  --ion-color-step-400: #797c83;
  --ion-color-step-450: #84868d;
  --ion-color-step-500: #8e9197;
  --ion-color-step-550: #989ba0;
  --ion-color-step-600: #a3a5aa;
  --ion-color-step-650: #adafb4;
  --ion-color-step-700: #b7b9be;
  --ion-color-step-750: #c2c3c8;
  --ion-color-step-800: #cccdd2;
  --ion-color-step-850: #d6d8db;
  --ion-color-step-900: #e0e2e5;
  --ion-color-step-950: #ebecef;
}

.md body.dark {
  --ion-background-color: #272b34;
  --ion-background-color-rgb: 39, 43, 52;

  --ion-text-color: #f5f6f9;
  --ion-text-color-rgb: 245, 246, 249;

  --ion-color-step-50: #31353e;
  --ion-color-step-100: #3c3f48;
  --ion-color-step-150: #464952;
  --ion-color-step-200: #50545b;
  --ion-color-step-250: #5b5e65;
  --ion-color-step-300: #65686f;
  --ion-color-step-350: #6f7279;
  --ion-color-step-400: #797c83;
  --ion-color-step-450: #84868d;
  --ion-color-step-500: #8e9197;
  --ion-color-step-550: #989ba0;
  --ion-color-step-600: #a3a5aa;
  --ion-color-step-650: #adafb4;
  --ion-color-step-700: #b7b9be;
  --ion-color-step-750: #c2c3c8;
  --ion-color-step-800: #cccdd2;
  --ion-color-step-850: #d6d8db;
  --ion-color-step-900: #e0e2e5;
  --ion-color-step-950: #ebecef;
}

/** Custom variables**/
.split-pane-visible > .split-pane-side {
  min-width: 80px !important;
  max-width: 80px !important;
  transition: 0.4s;
}

.split-pane-visible > .split-pane-side:hover {
  min-width: 240px !important;
  max-width: 240px !important;
  transition: 0.4s;
}

ion-content {
  --offset-bottom: auto !important;
  --overflow: hidden;
  overflow-y: scroll;
}

ion-content::-webkit-scrollbar {
  display: block;
  width: 5px !important;
}

ion-content::-webkit-scrollbar-thumb {
  background-color: #92939c !important;
  border-radius: 20px;
}

ion-content::-webkit-scrollbar-track {
  background-color: var(--ion-color-primary) !important;
  border-radius: 10px;
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents !important;
}