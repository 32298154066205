.modal>.modal-wrapper {
    height: 28vh !important;
    width: 60vh !important;
    border-radius: 0.6rem;
}

.modal-text {
    color: var(--ion-text-color);
    font-size: 1.2rem;
}

.modal-container-text-confirmation{
    margin: auto 0;
}

.modal-container-icon{
    margin: 1rem 0.5rem 0.5rem;
}

.modal-container-buttons{
    margin-bottom: 1rem;
}

.modal-icon{
    font-size: 2.5rem;
}

.modal-ion-button{
    font-size: 0.7rem;
}

.modal-ion-icon{
    font-size: 2rem;
}

@media (max-width: 3840px){
    .modal>.modal-wrapper {
        height: 20vh !important;
        width: 54vh !important;
    }

    .modal-container-icon{
        margin: 2rem 0.5rem 0.5rem;
    }
    
    .modal-container-buttons{
        margin-bottom: 2rem;
    }

    .modal-text{
        font-size: 2.4rem;
    }
    .modal-icon{
        font-size: 5.4rem;
    }

    .modal-ion-button{
        height: 4rem;
    }

    .modal-ion-icon-button{
        font-size: 4rem;
    }

}

@media (max-width: 2560px) {
    .modal>.modal-wrapper {
        height: 26vh !important;
        width: 58vh !important;
    }

    .modal-text{
        font-size: 1.8rem;
    }

    .modal-icon{
        font-size: 4rem;
    }

    .modal-ion-button{
        height: 4rem;
    }

    .modal-container-icon{
        margin: 1.2rem 0.5rem 0.5rem;
    }
    
    .modal-container-buttons{
        margin-bottom: 0.5rem;
    }

    .modal-ion-icon-button{
        font-size: 3.4rem;
    }
}

@media (max-width: 1920px){
    .modal>.modal-wrapper {
        height: 28vh !important;
        width: 58vh !important;
    }

    .modal-text{
        font-size: 1.2rem;
    }

    .modal-icon{
        font-size: 3rem;
    }

    .modal-ion-icon-button{
        font-size: 2.4rem;
    }
}

@media (max-width: 1366px){
    .modal>.modal-wrapper {
        height: 34vh !important;
    }
    .modal-container-icon{
        margin: 1.8rem 0.5rem 0.8rem;
    }

    .modal-text{
        font-size: 1rem;
    }

    .modal-icon{
        font-size: 2.5rem;
    }

    .modal-ion-icon-button{
        font-size: 2.2rem;
    }
}

@media (max-width: 820px){
    .modal>.modal-wrapper {
        height: 28vh !important;
        width: 56vh !important;
    }

    .modal-text{
        font-size: 1.3rem;
    }
    .modal-icon{
        font-size: 3.2rem;
    }

    .modal-ion-icon-button{
        font-size: 2.6rem;
    }
}

@media (max-width: 620px){
    .modal-text{
        font-size: 1.2rem;
    }

    .modal-icon{
        font-size: 3rem;
    }

    .modal-ion-icon-button{
        font-size: 2.5rem;
    }

    .modal-container-text-confirmation{
        margin-top: 4rem;
    }
}

@media (max-width: 520px){
    .modal-text{
        font-size: 1rem;
    }
    
    .modal-icon, .modal-ion-icon-button{
        font-size: 1.8rem;
    }

    .modal-container-text-confirmation{
        margin-top: 3rem;
    }
}