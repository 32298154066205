.item-inner {
  display: none;
}

.change-container-title {
  font-size: 1rem;
  color: var(--ion-text-color);
}

.col-client-change-container {
  border-radius: 1.2rem;
}

.toggle-size-change {
  width: 2.5rem;
  height: 25px;
  --handle-width: 1.5rem
}

.change-client-container-header {
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--cust-card-header-color);
}

/* @media screen and (min-width: 1920px) {
    .col-client-change-container{
      position: relative;
      bottom: -25px;
    }
  } */

.client-change {
  position: relative;
  border-radius: 0.625rem;
  background: var(--ion-color-light);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  padding-top: 0;
  margin: 0;
  width: 100%;
}

.conitaner-cards-client-change {
  display: grid;
  gap: 0.8rem;
}

.client-change-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 2.5rem;
  padding: 0px;
  background: var(--cust-card-header-color);
  text-align: center;
}

.client-change-title {
  font-size: 100%;
  font-weight: 700;
  width: 100%;
  align-self: center;
  padding: 0 10%;
  margin: 0;
}

.client-change-content-alignment {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.client-change-content-title {
  font-size: 14px;
  text-align: center;
}


.client-change-icon-header {
  font-size: 2.5vh;
  position: absolute;
}

.change-status-icon-not-checked {
  color: var(--ion-color-tertiary-contrast);
}

.change-status-icon-checked {
  color: var(--ion-color-secondary);
}


@media (min-width: 2560px) {

  .col-client-change-container {
    margin-top: 2.8rem !important;
  }

  .conitaner-cards-client-change {
    grid-template-columns: repeat(5, 1fr);
  }

  .client-change-title {
    font-size: 1.2rem;
  }

  .client-change-icon-header {
    font-size: 2vh;
    margin: 0.2rem;
  }

  .change-container-title {
    font-size: 1.8rem;
  }

  .client-change-content-title {
    font-size: 1.2rem;
  }

  .change-client-container-header {
    height: 2.6rem;
  }

  .client-change-title {
    font-size: 1.2rem;
  }

  .client-change {
    height: 10rem;
  }

  .toggle-size-change {
    width: 4rem;
    height: 2.6rem;
    --handle-width: 2.2rem;
    --handle-height: 2rem;
    --handle-spacing: 0.2rem;
    border-radius: 45px;
  }

}


@media (min-width: 3840px) {

  .col-client-change-container {
    margin-top: 3.8rem !important;
  }

  .conitaner-cards-client-change {
    grid-template-columns: repeat(5, 1fr);
  }

  .change-status-icon-checked,
  .change-status-icon-not-checked {
    font-size: 1.5vh;
    margin: 0.2rem;
  }

  .client-change-title {
    font-size: 1.4rem;
  }

  .change-container-title {
    font-size: 2rem;
  }

  .client-change-content-title {
    font-size: 1.4rem;
  }

  .client-change {
    height: 12rem;
  }

  .change-client-container-header {
    height: 2.8rem;
  }

  .toggle-size-change {
    width: 4.6rem;
    height: 3.2rem;
    --handle-width: 2.6rem;
    --handle-height: 2.5rem;
    --handle-spacing: 0.3rem;
    border-radius: 45px;
  }
}


@media (max-width: 1920px) {
  .col-client-change-container {
    margin-top: 2.8rem !important;
  }

  .conitaner-cards-client-change {
    grid-template-columns: repeat(4, 1fr);
  }

  .change-status-icon-checked,
  .change-status-icon-not-checked {
    font-size: 2.3vh;
    top: 0.5rem;
    left: 0.5rem;
  }
}

@media (max-width: 1366px) {

  .client-change-content-title {
    text-align: center;
  }

  .col-client-change-container {
    margin-top: 1.8rem !important;
  }


  .client-change-icon-header {
    font-size: 2.8vh;
    top: 0.6rem;
    left: 0.6rem;
  }

}

@media (max-width: 820px) {

  .client-change-content-title {
    text-align: center;
  }

  .client-change-icon-header {
    font-size: 2.3vh !important;
    top: 0.5rem;
    left: 0.6rem;
  }

}

@media (max-width: 620px) {
  .client-change-icon-header {
    font-size: 2.2vh;
    margin-top: 0.2rem;
    top: 0.4rem;
  }

  .conitaner-cards-client-change {
    grid-template-columns: repeat(2, 1fr);
  }

}
