:root {
    --cust-ticks-size: 7;
}

.main-graph-card {
    padding: 0;
    margin: 10px;
    width: 100%;
    border-radius: 20px;
    background-color: var(--ion-color-primary);
}

.graph-card {
    padding: 0px;
    margin: 0px;
    position: relative;
    border-radius: 10px;
    background: var(--ion-color-light);
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

.graph-card-reports {
    padding: 0px;
    margin: 0px;
    position: relative;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

.graph-card-content {
    padding: 0;
}

.graph-card-header {
    padding-top: 5px;
    padding-bottom: 0px;
}

.graph-card-header .download-button {
    width: fit-content;
    position: absolute;
    right: 10px;
    top: 5px;
    cursor: pointer;
}

.graph-card-title {
    font-size: small;
}

.graph-card-title-reports{
    display: none;
}

.save-icon {
    font-size: small;
    color: var(--ion-color-secondary);
}

.save-icon-reports {
    font-size: 2vh;
    color: var(--ion-color-secondary);
}

@media (min-width: 992px) {

    .graph-card-header {
        padding-top: 20px;
        padding-bottom: 0px;
    }

    .graph-card {
        margin: 10px;
    }

    .graph-card-title {
        font-size: medium;
    }

    .save-icon {
        font-size: large;
    }

    .graph-card canvas {
        padding: 5px;
    }

    :root {
        --cust-ticks-size: 12;
    }

}