.graphs-card {
    display: flex;
}

.summary-cards {
    padding: 2%;
}

.col-graphics-generalData {
    justify-content: center !important;
}

.icon-scroll-up {
    position: fixed;
    bottom: 55.3px;
    right: 20.3px;
    font-size: 19px;
    color: var(--ion-color-secondary);
    cursor: pointer;
}

.icon-scroll-up-circle {
    position: fixed;
    bottom: 50px;
    right: 15px;
    font-size: 30px;
    color: var(--cust-scroll-icon-color);
    cursor: pointer;
}

.icon-msgs-up {
    position: fixed;
    bottom: 96px;
    right: 20px;
    font-size: 19px;
    cursor: pointer;
}

.color-true {
    color: var(--ion-color-secondary);
}

.color-false {
    color: var(--ion-color-tertiary);
}

.icon-msgs-up-circle {
    position: fixed;
    bottom: 90px;
    right: 15px;
    font-size: 30px;
    color: var(--cust-scroll-icon-color);
    cursor: pointer;
}

.content-card-error {
    padding: 4% !important;
    ;
}

.warning-icon {
    color: var(--cust-color-umbral);
    font-size: 9em;
}

.icon-card {
    height: 100%;
}

@media (min-width: 3840px) {

    .lineChart-content {
        flex: 0 0 calc(calc(2 / var(--ion-grid-columns, 12)) * 100%) !important;
        width: calc(calc(2 / var(--ion-grid-columns, 12)) * 100%) !important;
        max-width: calc(calc(2 / var(--ion-grid-columns, 12)) * 100%) !important;
    }

    .graph-card-title {
        font-size: 1.2vh;
    }

}


@media (max-width: 2560px) {

    .lineChart-content {
        flex: 0 0 calc(calc(3 / var(--ion-grid-columns, 12)) * 100%) !important;
        width: calc(calc(3 / var(--ion-grid-columns, 12)) * 100%) !important;
        max-width: calc(calc(3 / var(--ion-grid-columns, 12)) * 100%) !important;
    }

    .graph-card-title {
        font-size: x-large;
    }

}

@media (max-width: 1920px) {
    .graph-card-title {
        font-size: 2vh;
    }
}

@media (max-width: 1800px) {
    .graph-card-title {
        font-size: 3vh;
    }
}

@media(max-width: 1700px) {
    .col-graphics-generalData {
        width: 100% !important;
    }

    .lineChart-content {
        flex: 0 0 calc(calc(6 / var(--ion-grid-columns, 12)) * 100%) !important;
        width: calc(calc(6 / var(--ion-grid-columns, 12)) * 100%) !important;
        max-width: calc(calc(6 / var(--ion-grid-columns, 12)) * 100%) !important;
    }

}

@media (min-width: 992px) {

    .icon-scroll-up {
        bottom: 61.3px;
    }

    .icon-scroll-up-circle {
        bottom: 56px;
    }

    .icon-msgs-up {
        bottom: 100px;
    }

    .icon-msgs-up-circle {
        bottom: 94px;
    }
}

@media (max-width: 992px) {
    .warning-icon {
        font-size: 5em;
    }

    .content-card-error {
        padding: 0% !important;
        ;
    }

    .graph-card-title {
        font-size: x-small;
    }
}

@media(max-width: 520px) {
    .col-content-message {
        padding: 0;
    }

    .lineChart-content {
        flex: 0 0 calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
        width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
        max-width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
    }
}