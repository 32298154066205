.person-icon {
  font-size: 1000%;
  color: var(--cust-body-color);
}

.profile-card {
  border-radius: 10px;
}

.profile-text {
  margin-bottom: 0.9rem;
}

.profile-text-bold {
  font-weight: bold !important;
}

.profile-subtitle {
  font-size: 16px;
}

.profile-button {
  --border-radius: 12px;
  color: var(--cust-button-color);
  --border-color: var(--cust-button-color);
}

.profile-button:hover {
  border-radius: 12px;
  background: var(--cust-button-color);
  color: white;
}

.profile-row-button {
  justify-content: flex-end;
}

.profile-input {
  font-size: 0.7rem;
}

.profile-item {
  border: 1px solid #b7bec2;
  border-radius: 15px;
  padding: 0px !important;
}

.profile-icon-eye {
  font-size: 1rem;
  color: var(--ion-color-secondary);
}

.profile-valid-text {
  display: block;
  font-size: 0.6rem;
  color: var(--cust-valid-error-color);
  padding-left: 15px;
}

.profile-modal {
  --max-width: 300px;
  --max-height: 200px;
  --border-radius: 10px;
  --background: var(--cust-modal-color-rgb);
}

.profile-modal-text {
  color: var(--ion-color-medium-contrast);
}

.toggle-size {
  width: 40px;
  height: 25px;
  --handle-width: 20px
}

.toggle-label {
  color: var(--ion-color-primary-contrast) !important;
}

.profile-card span {
  display: block;
}

@media (max-width: 3840px) {
  .profile-userName {
    font-size: 2.6rem;
  }

  .profile-icon-eye {
    font-size: 1.3rem;
  }

  .profile-card span,
  .profile-subtitle {
    font-size: 1.8rem;
    margin: 0.3rem 0;
  }

  .profile-title-config-user {
    font-size: 2rem;
  }

  .profile-input,
  .profile-valid-text {
    font-size: 1.3rem;
  }

  .profile-button {
    zoom: 2
  }

  .perfil-labels {
    font-size: 1.6rem;
    margin-bottom: 0.8rem;
  }

  .container-userProfile {
    margin-top: 1.6rem;
  }
}


@media (max-width: 2560px) {

  .profile-valid-text {
    font-size: 1rem;
  }

  .profile-userName {
    font-size: 1.6rem;
  }

  .profile-input {
    font-size: 0.8rem;
  }

  .profile-button {
    zoom: 1
  }

  .perfil-labels {
    font-size: 1rem;
  }

  .profile-card span,
  .profile-subtitle {
    font-size: 1.2rem;
    margin: 0.3rem 0;
  }

  .profile-title-config-user {
    font-size: 1.8rem;
  }
}


@media (max-width: 1920px) {

  .profile-card span,
  .profile-subtitle {
    font-size: 1rem;
    margin: 0.3rem 0;
  }

  .profile-userName,
  .profile-title-config-user {
    font-size: 1.6rem;
  }

  .profile-card span {
    font-size: 0.8rem;
  }

}

@media (max-width: 992px) {

  .person-icon {
    font-size: 800%;
    text-align: left;
  }

  .profile-text-mail {
    font-size: 100%;
    padding-left: 10px;
  }

  .profile-row-button {
    justify-content: center;
  }

  .pofile-card-fixed {
    position: relative;
    width: auto;
  }

  .profile-input {
    padding-left: 10px !important;
  }
}