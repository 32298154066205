.ABMKpisTitleCards {
    color: var(--ion-color-step-850, #262626);
    font-size: 4vh;
    font-weight: 500;
    line-height: 1.2;
    padding: 1%;
    margin-left: 5px;
    text-align: left;
}

.ABMKpisTitleKpi {
    color: var(--ion-color-step-850, #262626);
    font-size: 4vh;
    text-align: center;
    padding: 1%;
}

.ABMKpiTitleKpi {
    color: var(--ion-color-step-850, #262626);
    font-size: 4vh;
    text-align: center;
    padding: 1%;
}

.ABMKpisCardSubtitle {
    font-size: 2.5vh;
    color: var(--ion-color-step-850, #262626);
    padding-left: 1%;
    padding-bottom: 1%;
}

.ABMKpisButtonContainer {
    text-align: center;
}

.buttonkpi {
    margin-bottom: 2%;
    --border-radius: 12px;
    color: var(--cust-button-color);
    --border-color: var(--cust-button-color);
}

.buttonkpi:hover {
    border-radius: 12px;
    border-color: var(--cust-button-color);
    background-color: var(--cust-button-color);
    color: white;
}

.iconPosition {
    padding-right: 1%;
}

.ABMKpisIconPositionSee {
    padding-right: 1%;
    cursor: pointer;
}

.ABMKpisGrid {
    padding: 0;
    padding-top: 2%;
}

.ABMKpisCard {
    color: var(--ion-color-step-850, #262626);
    text-align: center;
    background: var(--ion-color-light);
}

.ABMKpisCard ion-row {
    border-bottom: 1px solid var(--ion-color-step-500);
}

.ABMKpisRow {
    color: var(--ion-color-step-850, #262626);
    font-weight: bold;
}

.contentKpis {
    display: flex;
}

.ABMKpisCardForm {
    width: 80%;
    margin: auto;
    margin-top: 3vh;
    margin-bottom: 5vh;
}

.ABMKpisCardFormAdd {
    width: 80%;
    margin: auto;
    margin-top: 10%;
    margin-bottom: 5vh;
}

.ABMKpisLabelKpis {
    padding-left: 5%;
    color: var(--ion-color-step-850, #262626);
    font-weight: bold;
    font-size: 2.5vh;
}

.ABMKpisInputKpis {
    font-size: 2vh !important;
    border: 1px solid #b7bec2;
    border-radius: 15px;
    padding-left: 2% !important;
}

.ABMKpisSelectKpis {
    padding-left: 2% !important;
    min-width: 100%;
    font-size: 2vh !important;
    border: 1px solid #b7bec2;
    border-radius: 15px;
}

.customSelectKpiGroup .popover-content {
    overflow-y: scroll !important;
}

.customSelectKpiGroup .popover-content::-webkit-scrollbar {
    display: block;
    width: 5px !important;
}

.customSelectKpiGroup .popover-content::-webkit-scrollbar-thumb {
    background-color: #92939c !important;
    border-radius: 20px;
}

.customSelectKpiGroup .popover-content::-webkit-scrollbar-track {
    background-color: var(--ion-color-primary) !important;
    border-radius: 10px;
}

.containerButtonKpis {
    padding: 2%;
}

.ABMKpisButton {
    font-size: 6vh;
}

.customSelectKpiForm .popover-content {
    overflow: hidden !important;
}

.ABMKpis-icon-select-client {
    font-size: 3vh;
    color: var(--ion-color-secondary);
    margin-bottom: 2%;
}

.ABMKpis-title-select-client {
    font-size: 1.8em;
    color: --ion-color-primary;
    display: flex;
    align-items: center;
}

.divIconAdd {
    text-align: right;
}

/* MODAL SEE*/
.kpis-modal>.modal-wrapper {
    min-height: 70%;
    min-width: 40%;
    width: 55% !important;
    height: 90%;
    color: var(--ion-color-step-850, #262626);
    background: var(--ion-color-light);
}

.ABMKpisCardModal {
    font-size: 2.3vh;
    color: var(--ion-color-step-850, #262626);
    background: var(--ion-color-light);
    box-shadow: none;
}

.ABMKpisGridModal {
    text-align: center;
}

.cardSubtitleModal {
    font-size: 2.4vh;
    color: var(--ion-color-step-850, #262626);
}

.kpisAdd-modal .modal-wrapper {
    max-width: 20%;
    max-height: 20%;
}

.centerKpi {
    font-weight: bold;
}

.fontSizeKpi {
    font-size: 2vh;
}

.colorKpi {
    background-color: var(--ion-color-step-150);
}

/* MODAL ADD */
.ABMKpisModal>.modal-wrapper {
    height: 28vh !important;
    width: 60vh !important;
    border-radius: 10px;
}

.ABMKpisModalKpis>.modal-wrapper {
    height: 35vh !important;
    width: 68vh !important;
    border-radius: 10px;
}

.ABMKpisContainer {
    padding-top: 14%;
}

.ABMKpisContainerRemove {
    padding-top: 10%;
}

.ABMKpisContainerModify {
    padding-top: 8%;
}

.ABMKpisModalText {
    color: var(--ion-text-color);
    font-size: 3.5vh;
}

.padding-04 {
    padding-top: 0.4rem;
}

@media (min-width: 3840px) {
    .ABMKpisCardForm {
        margin-top: 4rem;
    }

    .ABMKpiTitleKpi {
        font-size: 3vh;
    }

    .ABMKpis-title-select-client {
        font-size: 2vh;
    }

    .ABMKpis-icon-select-client {
        font-size: 3vh;
    }

    .ABMKpisRow {
        font-size: 1.5vh !important;
    }

    .ABMKpisCol {
        font-size: 1.5vh !important;
    }

    .ABMKpiButton {
        font-size: 2vh;
    }

    .ABMKpisADD{
        margin-top: 3%;
    }
  
}

@media (min-width: 2560px) {
    .ABMKpis-content {
        margin-top: 3vh;
    }

    .ABMKpisRow {
        font-size: 2vh;
    }

    .ABMKpisCol {
        font-size: 2vh;
    }

    .ABMKpisTitleCards {
        font-size: 2.5vh;
    }

    .ABMKpisClient {
        margin-top: 3vh;
    }

    .ABMKpiButton {
        font-size: 2vh;
    }

    .buttonkpi {
        font-size: 1.8vh;
        height: 3vh;
    }

    .buttonkpi:hover {
        font-size: 1.8vh;
        height: 3vh;
    }

    .ABMKpisTitleKpi {
        font-size: 3vh;
        margin-top: 1%;
    }

    .ABMKpisButton {
        font-size: 2vh;
        padding: 1%;
    }

    .ABMKpisZoom{
        zoom: 2;
    }

    .ABMKpisLabelKpis {
        font-size: 2.2vh;
    }

    .ClassButtonKpis{
        height: 4vh;
    }

    .ABMKpis-title-select-client {
        font-size: 2.5vh;
    }

    .ABMKpis-icon-select-client {
        font-size: 3.5vh;
    }

    .divIconAdd {
        margin-top: -2.5vh;
    }

    .ABMKpisADD {
        margin: 2%;
    }

    .kpis-modal>.modal-wrapper {
        max-width: 1vh !important;
        max-height: 2vh !important;
    }
    
}

@media (max-width: 1920px) {

    .grid-metrics {
        margin-top: 2rem;
    }

    .ABMKpisClient {
        margin-top: 0;
    }

    .ABMKpis-content {
        margin-top: 2rem;
    }

    .ABMKpisCardForm {
        margin-top: 3rem;
    }

    .ABMKpisButton {
        font-size: 2.9rem;
    }

    .ABMKpisTitleCards {
        font-size: 2.9vh;
    }

    .ABMKpis-title-select-client {
        font-size: 1.5em;
    }

    .ABMKpisRow {
        font-size: 1.7vh;
    }

    .ABMKpisCol {
        font-size: 1.7vh;
    }

    .ABMKpis-icon-select-client {
        font-size: 3vh;
    }

    .ABMKpis-icon-select-client {
        font-size: 4vh;
        margin-bottom: 0;
    }

    .ABMKpiTitleKpi {
        font-size: 3.5vh;
    }

    .ABMKpisTitleKpi {
        font-size: 3.5vh;
    }

    .ABMKpisADD {
        margin-top: 3%;
    }

    .divIconAdd {
        margin-top: -5vh;
    }

    .kpis-modal>.modal-wrapper {
        max-width: 20% !important;
        max-height: 75% !important;
    }
}

@media(max-width: 1400px) {
    .ABMKpisButton {
        font-size: 4vh;
    }

    .ABMKpis-content {
        margin-top: 3rem;
    }

    .divIconAdd {
        margin-top: -7vh;
    }

    .kpis-modal>.modal-wrapper {
        max-width: 100% !important;
        max-height: 85% !important;
    }
}

@media (max-width:820px) {

    .kpis-modal>.modal-wrapper {
        width: 90% !important;
        height: 80%;
    }

    .ABMKpisTitleKpi {
        font-size: 3vh;
    }

    .tablet-hidden-kpis {
        display: none;
    }

    .ABMKpiTitleKpi {
        font-size: 3vh;
    }

    .ABMKpisCardSubtitle {
        font-size: 1.8vh;
    }

    .ABMKpisTitleCards {
        font-size: 2.9vh;
    }

    .ABMKpis-title-select-client {
        font-size: 2em;
    }

    .divIconAdd {
        margin-top: 0vh;
    }
}

@media(max-width: 670px) {

    .mobile-hidden-kpis {
        display: none;
    }

    .ABMKpisTitleCards {
        font-size: 2.5vh;
        margin-left: 0vh;
    }

    .ABMKpis-title-select-client {
        font-size: 1.6em;
    }

    .ABMKpisTitleKpi {
        font-size: 3vh;
    }

    .ABMKpisLabelKpis {
        font-size: 2vh;
    }

    .ABMKpisButton {
        font-size: 5vh;
    }

    .ABMKpisCardSubtitle {
        font-size: 2vh;
    }

    .cardSubtitleModal {
        font-size: 2.5vh;
        margin-bottom: 4%;
    }

    .kpisRow {
        text-align: center;
        font-size: 2vh;
    }

    .kpisRow-ultimo {
        text-align: center;
        font-size: 2vh;
        margin-bottom: 5%;
    }

    .divIconAdd {
        margin-top: -3.7vh;
    }

}

@media(max-width: 520px) {
    .ABMKpisTitleKpi {
        font-size: 2vh;
    }

    .ABMKpiTitleKpi {
        font-size: 2.6vh;
    }

    .ABMKpisLabelKpis {
        font-size: 1.8vh;
    }
    .kpis-modal>.modal-wrapper {
        width: 95% !important;
        height: 95% !important;
    }
}