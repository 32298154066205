.messages-container {
  border-radius: 20px;
  background-color: var(--ion-color-primary);
  justify-items: center;
  width: 100%;
}

.messages-container-header {
  background: var(--cust-card-header-alerts-color);
}

.messages-card,
.messages-card-content,
.messages-card-row,
.messages-card-col {
  height: 54px;
}

.messages-card {
  border-color: var(--ion-color-secondary);
}

.messages-card-false {
  border-color: var(--ion-color-tertiary);
}

.messages-card-content,
.messages-card-col {
  padding: 0px;
}

.messages-card-date {
  background-color: var(--ion-color-secondary);
  color: #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.messages-card-date-false {
  background-color: var(--ion-color-tertiary);
  color: #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.messages-card-message {
  display: flex;
  align-items: center;
  background: var(--ion-color-light);
}

.messages-card-text-msg {
  max-height: 54px;
  padding: 5px;
  width: auto;
  color: var(--ion-text-color);
}

.messages-modal {
  --max-width: 60%;
  --max-height: 400px;
  --border-radius: 10px;
  --background: var(--cust-modal-color-rgb);
}

.messages-modal-text {
  color: var(--ion-color-medium-contrast);
  margin-top: 5px;
  padding-top: 0px !important;
  margin-bottom: 5px;
  padding-bottom: 0px !important;
  font-size: 14px;
}

.messages-button {
  --border-radius: 10px;
  color: var(--cust-button-color);
  --border-color: var(--cust-button-color);
  width: max-content;
  --padding-top: 15px;
  --padding-bottom: 15px;
}

.messages-button:hover {
  border-radius: 10px;
  color: white;
  background-color: var(--cust-button-color);
}

.messages-button-date {
  --background: var(--cust-button-color);
  color: var(--ion-color-primary);
  margin-top: 15px;
  width: max-content;
  --padding-top: 15px;
  --padding-bottom: 15px;
}

.messages-grid-modal {
  display: flex;
  flex-direction: column;
  margin: auto;
  overflow-y: auto;
  align-items: center;
}

.messages-start-modal {
  justify-content: flex-start;
}

.messages-readmore {
  position: absolute;
  bottom: 2px;
  right: 2px;
  cursor: pointer;
}

@media (min-width: 3840px) {
  .messages-title-header {
    font-size: 2.5rem;
  }
  .messages-text-date, .messages-card-text-msg {
    font-size: 2rem !important;
  }
  .message-history{
    font-size: 2rem;
  }
}

@media (min-width: 2560px) {
  .messages-title-header {
    font-size: 1.4rem;
  }
  .messages-text-date, .messages-card-text-msg {
    font-size: 1.2rem !important;
  }
  .message-history{
    font-size: 1.2rem;
  }
}

@media (min-width: 1920px) {
  .messages-container{
    margin-top: 3rem;
  }
  .messages-title-header {
    font-size: 1.2rem;
  }
  .messagePrismaHistorial-card-container {
    margin-top: 1.8rem;
  }
  .messages-text-date, .messages-card-text-msg {
    font-size: 1rem !important;
  }

}

@media (max-width: 1366px) {
  .messages-title-header {
    font-size: 1rem;
  }
  .message-history{
    font-size: 0.8rem;
  }
}

@media (max-width: 992px) {

  .messages-card-text-msg,
  .messages-text-date {
    font-size: 12px !important;
  }

  .messages-card-content-2 {
    padding: 0px;
  }
}

@media (max-width: 576px) {

  .messages-modal {
    --max-width: 300px;
  }

  .messages-readmore {
    position: relative;
  }
}