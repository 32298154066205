.inactive-container {
    place-items: center;
}

.inactive-header {
    position: absolute;
    background-color: var(--ion-color-primary);
    padding: 0;
    margin: 0;
}

.inactive-img-logo {
    height: 6em;
}

.inactive-img-logo-bancos {
    height: 4em;
    margin: 1%;
}

.inactive-card {
    width: 50%;
    top: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.inactive-card-header {
    text-align: center;
}

.inactive-card-icon {
    font-size: 10em;
    color: #EF4161;
}

.inactive-card-text {
    text-align: center;
    font-size: x-large;
}

.inactive-button {
    color: var(--cust-button-color);
    --border-color: var(--cust-button-color);
    --border-radius: 12px;
}

.inactive-button:hover {
    border-radius: 12px;
    --background: var(--cust-button-color);
    color: white;
}

@media (min-width: 768px) and (min-height: 882px) and (max-width: 1764px) and (max-height:1764px)and (orientation:portrait){
    .inactive-card {
        width: 60vh;
        height: 45vh;
        top: 20%;
    }
    .inactive-card-icon {
        font-size: 9.5vh;
    }
    .inactive-card-text {
        font-size: 2.6vh;
    }
    .inactive-button{
        height: 4.6vh;
        width: 16vh;
        font-size: 58%;
    }
}

@media (min-width: 280px) and (max-width: 425px)and (orientation:portrait){
    .inactive-card {
        width: 40vh;
        height: 50vh;
    }
    .inactive-card-icon {
        font-size: 9.5vh;
    }
    .inactive-card-text {
        font-size: 2.6vh;
    }
    .inactive-button{
        height: 4.6vh;
        width: 16vh;
        font-size: 58%;
    }
}

@media (min-width: 1366px) and (max-width: 1919px)and (orientation:landscape){
    .inactive-card {
        width: 78vh;
        height: 38vh;
    }
    .inactive-card-icon {
        font-size: 9.5vh;
    }
    .inactive-card-text {
        font-size: 2.6vh;
    }
    .inactive-button{
        height: 4.6vh;
        width: 16vh;
        font-size: 58%;
    }
}
@media (min-width: 1920px) and (max-width: 2559px)and (orientation:landscape){

    .inactive-card {
        width: 70.5vh;
        height: 32vh;
    }
    .inactive-card-icon {
        font-size: 8.7vh;
    }

    .inactive-card-text {
        font-size: 2vh;
    }
}

@media (min-width: 2560px) and (max-width: 3839px)and (orientation:landscape){
    .inactive-card {
        height: 32vh;
        width: 70vh;
    }
    .inactive-button{
        height: 2.9vh;
        width: 12.5vh;
        font-size: 70%;
    }
    .inactive-card-icon {
        font-size: 9vh;
    }
}

@media (min-width: 3840px) and (orientation:landscape){
    .inactive-card{
        height: 35vh;
        width: 70vh;
        border-radius: 10px;
    }
    .inactive-card-icon {
        font-size: 8.5vh;
    }
    .inactive-card-text {
        font-size: 1.9vh;
    }
    .inactive-button{
        height: 3vh;
        width: 13vh;
        font-size: 66%;
    }
}