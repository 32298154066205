.list-clients-modal {
  --max-width: 80%;
  /* --max-height: 95%; */
  --border-radius: 10px;
  --background: var(--cust-modal-color-rgb);
}

.list-clients-modal-list {
  overflow-y: auto;
  padding-bottom: 10px;
}

.list-clients-modal-category {
  text-align: center;
  color: #b7bec2;
}

.list-clients-modal-item {
  text-align: center;
  margin: auto 15px;
  --border-color: var(--ion-color-secondary);
}

.list-clients-modal-item .item-inner {
  border-bottom: 2px solid var(--ion-color-secondary);
}

ion-item.list-clients-modal-category::part(native) {
  padding-left: unset;
}

ion-item.list-clients-modal-item::part(native) {
  padding-left: unset;
}

.list-client-modal-selected {
  color: #29b2b7;
}

.list-clients-modal-category-label {
  display: flex !important;
  flex-direction: row;
  padding-left: 16px;
}

.list-clients-modal-category-label::before,
.list-clients-modal-category-label::after {
  content: "";
  flex: 1 1;
  border-bottom: 2px solid #b7bec2;
  margin: auto;
}

.list-clients-modal-category-label::before {
  margin-right: 10px
}

.list-clients-modal-category-label::after {
  margin-left: 10px
}

.list-clients-modal-button {
  --border-radius: 8px;
  color: var(--cust-button-color);
  --border-color: var(--cust-button-color);
}

.list-clients-modal-button:hover {
  border-radius: 12px;
  border-color: var(--cust-button-color);
  background-color: var(--cust-button-color);
  color: white;
}

@media(min-width: 3840px){
  .list-clients-modal-item, .list-clients-modal-category-label{
    font-size: 1.7rem !important;
  }

  .list-clients-modal div[role='dialog']{
    height: 56%;
  } 

  .list-clients-modal-button{
    font-size: 1.2rem;
  }
}

@media(min-width: 2560px){
  .list-clients-modal-item, .list-clients-modal-category-label{
    font-size: 1.4rem !important;
  }
  .list-clients-modal {
    --max-width: 24rem !important;
  }
  .list-clients-modal-button{
    font-size: 1rem;
    width: fit-content; 
  }

  .list-clients-modal div[role='dialog']{
    height: 75%;
  } 
}

@media(min-width: 768px) {
  .list-clients-modal {
    --max-width: 300px;
    --max-height: 95%;
  }

  .list-clients-modal-list::-webkit-scrollbar {
    display: block;
    width: 5px !important;
  }

  .list-clients-modal-list::-webkit-scrollbar-thumb {
    background-color: #92939c !important;
    border-radius: 20px;
  }

  .list-clients-modal-list::-webkit-scrollbar-track {
    background-color: var(--ion-color-primary) !important;
    border-radius: 10px;
  }

  .list-clients-modal-item {
    --inner-padding-end: 0px;
    --padding-start: 0px
  }
}