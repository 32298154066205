.ABMPrismaMessagesFormTitle .ABMClientsFormTitleMsj {
    color: var(--ion-text-color, #000);
    font-size: 2.8vh;
    padding-top: 1%;
}

.ABMPrismaMessagesIcon {
    font-size: 3.5vh;
}

.ABMPrismaClients {
    padding: 0%;
}

.toggle-ABMPrismaMessages-item {
    --ion-background-color: var(--ion-color-light);
}

.toggle-ABMPrismaMessages-text {
    font-size: 2.1vh;
}

.toggle-ABMPrismaMessages {
    opacity: revert;
    zoom: 0.6;
}

.ABMPrismaMessagesInputTitles {
    font-size: 2vh;
    color: var(--ion-text-color);
}

.ABMPrismaMessagesCardClients {
    background: var(--ion-color-light);
    border-radius: 10px;
}

.AMBPrismaMessagesContainerDescartar {
    margin-top: 3%;
    display: inline-flex;
}

.ABMPrismaMessagesItem {
    border: 0.5px solid #b7bec2;
    border-radius: 15px;
    padding: 0 !important;
    margin: auto;
    margin-top: 2%;
}

.ABMPrismaMessagesCheckbox {
    margin-left: 2%;
    --checkmark-color: #ffffff;
    margin-top: 1%;
}

.ABMPrismaMessagesItemTextarea {
    border: 1px solid #b7bec2;
    border-radius: 15px;
    padding: 0 !important;
    width: 97.5%;
    margin: auto;
    margin-top: 1%;
}

.ABMMensaje {
    margin-left: 1%;
}

.AMBButtonMensajeContainer {
    justify-content: center;
}

.ABMPrismaMessagesIconButton {
    font-size: 4.5vh;
}

.ABMPrismaMessagesCardTable {
    background: var(--ion-color-light);
}

.ABMPrismaMessageActive {
    font-size: 2.3vh;
}

.ABMPrismaMessagesGrid {
    text-align: center;
}

.ABMPrismaMessagesCol {
    color: var(--ion-text-color);
    font-size: 2.3vh;
}

.ABMPrismaMessagesColTitle {
    font-weight: 500;
}

.ABMPrismaMessagesButtonSimple {
    margin-bottom: 2%;
    --border-radius: 12px;
    color: var(--cust-button-color);
    --border-color: var(--cust-button-color);
}

.ABMPrismaMessagesButtonSimple:hover {
    border-radius: 12px;
    border-color: var(--cust-button-color);
    background-color: var(--cust-button-color);
    color: white;
}

.mobile-text {
    display: none;
}


.ABMPrismaMessagesGrid ion-row {
    border-bottom: 1px solid var(--ion-color-step-500);
}


@media (min-width: 3840px) {

    .ABMPrismaMessagesFormTitle {
        padding-top: 1% !important;
        padding-left: 2%;
    }

    .ABMPrismaMessagesIcon {
        font-size: 1.5vh !important;
        zoom: 180%;
    }

    .ABMPrismaMessagesCardClients {
        margin-top: 2%;
    }

    .ABMPrismaMessagesCheckbox {
        margin-top: 2%;
        zoom: 200%;
    }

    .AMBButtonMensajeContainer {
        justify-content: center;
        margin: 2%;
        zoom: 140%;
    }

    .ABMPrismaMessagesIconButton {
        font-size: 2vh !important;
    }

    .AMBButtonMensajeContainer {
        zoom: 200% !important;
    }
}

@media (min-width: 2560px) {

    .ABMPrismaMessagesFormCard {
        margin-top: 3%;
    }

    .toggle-ABMPrismaMessages {
        opacity: revert;
        zoom: 1;
    }
    
    .ABMPrismaMessagesFormTitle {
        color: var(--ion-text-color, #000);
        font-size: 2.4vh;
        padding-top: 1%;
    }

    .ABMPrismaMessagesIcon {
        font-size: 2.5vh;
        margin-top: 14%;
    }

    .ABMPrismaMessagesCardClients {
        margin-bottom: 2%;
    }

    .AMBPrismaMessagesContainerDescartar {
        margin-top: 2%;
    }

    .ABMPrismaMessagesCheckbox {
        margin-top: 2.5%;
    }

    .ABMPrismaMessagesInput {
        font-size: 1.8vh;
    }

    .ABMMensaje {
        margin-top: 2%;
    }

    .ABMPrismaMessagesTextarea {
        font-size: 1.8vh;
    }

    .AMBButtonMensajeContainer {
        justify-content: center;
        margin: 2%;
        zoom: 140%;
    }

    .ABMPrismaMessagesIconButton {
        font-size: 3vh;
    }
}

@media (max-width: 1920px) {

    .ABMPrismaMessagesFormCard {
        margin-top: 2%;
    }

    .ABMPrismaMessagesIcon {
        font-size: 3vh;
    }

    .ABMPrismaMessagesFormTitle {
        padding-top: 0.5%;
    }

    .ABMMensaje {
        margin-top: 2%;
    }

    .AMBButtonMensajeContainer {
        margin-top: 2%;
    }

    .ABMPrismaMessagesCheckbox {
        margin-top: 2%;
    }

    .AMBButtonMensajeContainer {
        zoom: 140%;
    }

    .ABMPrismaMessagesIconButton {
        font-size: 3vh;
    }

    .ABMPrismaMessagesTable {
        margin-top: 3%;
    }
}

@media(max-width: 1400px) {

    .ABMPrismaMessagesFormTitle {
        padding-top: 1%;
        padding-left: 2%;
    }

    .ABMPrismaMessagesInput {
        font-size: 2vh;
    }

    .ABMPrismaMessagesTextarea {
        font-size: 2vh;
    }

    .ABMPrismaMessagesCheckbox {
        margin-top: 0%
    }
}

@media (max-width:820px) {

    .ABMPrismaMessagesFormTitle {
        font-size: 2.4vh;
        padding-top: 6%;
    }

    .ABMPrismaMessagesIcon {
        font-size: 3vh;
        padding-left: 40%;
    }

    .ABMPrismaMessagesCheckbox {
        margin-top: 0.75%;
    }

    .AMBButtonMensajeContainer {
        margin-top: 2%;
    }

    .ABMPrismaMessagesIconButton {
        font-size: 4vh;
    }

    .AMBButtonMensajeContainer {
        zoom: 100%;
    }

    .mobile-hidden-messages {
        display: none;
    }

    .mobile-text {
        display: block;
        font-weight: 600;
    }

    .ABMClientsFormTitleMsj {
        margin-top: 2%;
        text-align: center;
    }

}

@media(max-width: 520px) {

    .ABMPrismaMessagesFormTitle {
        font-size: 2.2vh;
        padding-top: 4.5%;
    }

    .toggle-ABMPrismaMessages-text {
        font-size: 1.7vh;
    }

    .toggle-ABMPrismaMessages {
        padding-left: 0;
        padding-right: 2%;
    }

    .ABMPrismaMessagesCheckbox {
        margin-top: 2%;
    }

    .ABMPrismaMessagesFormCard {
        margin-top: 4%;
    }

    .ABMPrismaMessagesIcon {
        font-size: 3vh;
        padding-top: 4.5%;
        padding-left: 0%;
    }

    .ABMPrismaMessagesTextarea {
        margin-left: 2%;
    }

    .ABMPrismaMessagesInput {
        margin-left: 2%;
    }

    .mobile-hidden-messages {
        display: none;
    }

    .mobile-text {
        display: block;
        font-weight: 600;
    }

    .ABMClientsFormTitleMsj {
        margin-top: 2%;
        text-align: center;
    }

    .ABMPrismaMessagesCol {
        font-size: 2vh;
    }
}