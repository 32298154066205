.debin-card {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;
}

.debin-graphics-content {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;
}

.debin-graphics-content-hidden {
    display: none;
}

.content-button-cards{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0;
}

/* DEBIN BUTTON */

.debin-button-card {
    text-align: center;
    background: var(--ion-color-light);
}

.debin-button-title{
    padding: 2%;
    font-size: 2vh;
    color: var(--ion-text-color);
}

.debin-button-content{
    background-color: var(--ion-color-success);
    padding: 2%;
}

.debin-button-content-alert{
    background-color: var(--cust-color-alerted);
    padding: 2%;
}

.debin-button-text{
    color: var(--ion-text-color);
    font-weight: 600;
}

.lifespan-icon {
    color: var(--ion-color-success);
    padding: 0px;
    margin-right: 0.5%;
    font-size: 2vh;
}

.lifespan-icon-alerted {
    color: var(--cust-color-alerted);
    padding: 0px;
    margin-right: 0.5%;
    font-size: 2vh;
}

.lifespan-icon-undefined{
    color: var(--ion-color-step-750);
    padding: 0px;
    margin-right: 0.5%;
    font-size: 2vh;
}

@media (min-width: 1920px) {

    .debin-card {
        grid-template-columns: repeat(3, 1fr);
    }

    .debin-group-content-percentage {
        flex: 0 0 calc(calc(5 / var(--ion-grid-columns, 12)) * 100%) !important;
        width: calc(calc(5 / var(--ion-grid-columns, 12)) * 100%) !important;
        max-width: calc(calc(5 / var(--ion-grid-columns, 12)) * 100%) !important;
    }

    .debin-group-content {
        flex: 0 0 calc(calc(7 / var(--ion-grid-columns, 12)) * 100%) !important;
        width: calc(calc(7 / var(--ion-grid-columns, 12)) * 100%) !important;
        max-width: calc(calc(7 / var(--ion-grid-columns, 12)) * 100%) !important;
    }

    .debin-graph-content-card{
        flex: 0 0 calc(calc(6 / var(--ion-grid-columns, 12)) * 100%) !important;
        width: calc(calc(6 / var(--ion-grid-columns, 12)) * 100%) !important;
        max-width: calc(calc(6 / var(--ion-grid-columns, 12)) * 100%) !important;
    }

    .debin-button-title {
        font-size: 1.8vh;
    }

    .debin-button-text {
        font-size: 1.8vh;
    }

    .lifespan-icon, .lifespan-icon-alerted, .lifespan-icon-undefined {
        font-size: 1.6vh;
    }

}

@media (max-width: 992px) {
    .debin-group-content-percentage {
        flex: 0 0 calc(calc(5 / var(--ion-grid-columns, 12)) * 100%) !important;
        width: calc(calc(5 / var(--ion-grid-columns, 12)) * 100%) !important;
        max-width: calc(calc(5 / var(--ion-grid-columns, 12)) * 100%) !important;
    }

    .debin-group-content {
        flex: 0 0 calc(calc(7 / var(--ion-grid-columns, 12)) * 100%) !important;
        width: calc(calc(7 / var(--ion-grid-columns, 12)) * 100%) !important;
        max-width: calc(calc(7 / var(--ion-grid-columns, 12)) * 100%) !important;
    }

    .debin-graph-content-card{
        flex: 0 0 calc(calc(6 / var(--ion-grid-columns, 12)) * 100%) !important;
        width: calc(calc(6 / var(--ion-grid-columns, 12)) * 100%) !important;
        max-width: calc(calc(6 / var(--ion-grid-columns, 12)) * 100%) !important;
    }

    .debin-button-title {
        font-size: 1.6vh;
    }

    .lifespan-icon, .lifespan-icon-alerted, .lifespan-icon-undefined {
        font-size: 1.6vh;
    }
}

@media (max-width: 520px) {
    .debin-group-content-percentage {
        flex: 0 0 calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
        width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
        max-width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
    }

    .debin-group-content {
        flex: 0 0 calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
        width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
        max-width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
    }

    .debin-graph-content-card{
        flex: 0 0 calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
        width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
        max-width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
    }

    .content-button-cards {
        grid-template-columns: repeat(1, 1fr);
    }

    .lifespan-icon, .lifespan-icon-alerted, .lifespan-icon-undefined {
        font-size: 1.2vh;
    }
}
