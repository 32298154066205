/* REVERSAL PERCENTAGE */

.paystore-extra{
    font-size: 1.9vh;
    padding: 0;
    margin: 0;
}

.reversal-size-xl{
    display: none;
}

.row-reversal-conteiner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0;
}

.row-reversal {
    border: 1px var(--ion-text-color) solid;
    border-radius: 5px;
    margin-left: 10%;
    margin-right: 10%;
}

.row-reversal-alert {
    border: 1px #f5f6f9 solid;
    color: #f5f6f9;
    border-radius: 5px;
    margin-left: 10%;
    margin-right: 10%;
    background-color: var(--ion-color-tertiary);
    margin-bottom: 2.5%;
    animation: reversal-alert 700ms linear infinite alternate;
}

@keyframes reversal-alert {

    0% {
        background-color: hsl(348, 78%, 55%);
    }

    100% {
        background-color: hsl(348, 69%, 64%);
    }
}

@media (max-width: 2560px) {
    .row-reversal {
        margin-bottom: 3%;
    }
}

@media (min-width: 1920px) {

    .paystore-card-content {
        height: fit-content;
    }

    .paystore-card {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 0;
    }

    .paystore-group-content {
        flex: 0 0 calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
        width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
        max-width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
    }

    .row-reversal-conteiner {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 0;
    }

    .reversal-size{
        display: none;
    }

    .row-reversal {
        margin-left: 2%;
        margin-right: 2%;
    }
    
    .row-reversal-alert {
        margin-left: 2%;
        margin-right: 2%;
    }

    .reversal-size-xl{
        display: contents;
    }

    .paystore-extra {
        font-size: 1.5vh;
    }

}

@media(max-width: 912px) {

    .row-reversal {
        margin-left: 3%;
        margin-right: 3%;
    }
    
    .row-reversal-alert {
        margin-left: 3%;
        margin-right: 3%;
    }

    .paystore-extra {
        font-size: 1.5vh;
    }

    .paystore-group-content {
        flex: 0 0 calc(calc(6 / var(--ion-grid-columns, 12)) * 100%) !important;
        width: calc(calc(6 / var(--ion-grid-columns, 12)) * 100%) !important;
        max-width: calc(calc(6 / var(--ion-grid-columns, 12)) * 100%) !important;
    }
}

@media(max-width: 520px) {

    .paystore-group-content {
        flex: 0 0 calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
        width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
        max-width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
    }
}