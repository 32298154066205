.exp-session-modal {
    --max-width: 40%;
    --max-height: 35%;
    --border-radius: 10px;
    --background: var(--cust-modal-color-rgb);
}

.exp-session-content {
    justify-content: center;
}

.exp-session-text {
    color: var(--ion-color-medium-contrast);
    font-size: 3.5vh;
    padding-top: 5%;
}

.exp-session-icon {
    font-size: 8vh;
    color: var(--ion-color-secondary);
    animation: session 2s linear infinite normal;
    animation: name duration timing-function delay iteration-count direction fill-mode;

}

@keyframes session {
    from {
        transform: rotate(0deg);
    }

    12.5% {
        transform: rotate(45deg);
    }

    25% {
        transform: rotate(90deg);
    }

    37.5% {
        transform: rotate(135deg);
    }

    50% {
        transform: rotate(180deg);
    }

    62.5% {
        transform: rotate(225deg);
    }

    75% {
        transform: rotate(270deg);
    }

    87.5% {
        transform: rotate(315deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.exp-session-button {
    --border-radius: 12px;
    color: var(--cust-button-color);
    --border-color: var(--cust-button-color) !important;
    margin-bottom: 8%;
}

.exp-session-button:hover {
    border-radius: 12px;
    background: var(--cust-button-color);
    color: white;
}

@media(max-width: 640px) {

    .exp-session-modal {
        --max-width: 70%;
        --max-height: 40%;
    }

    .exp-session-text {
        font-size: 3vh;
        padding-top: 10%;
    }

    .exp-session-button {
        font-size: 2vh;
        margin-bottom: 15%;
    }
}