.newpay-card {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;
}

.newpay-content-card {
    flex: 0 0 calc(calc(4 / var(--ion-grid-columns, 12)) * 100%) !important;
    width: calc(calc(4 / var(--ion-grid-columns, 12)) * 100%) !important;
    max-width: calc(calc(4 / var(--ion-grid-columns, 12)) * 100%) !important;
}

.newpay-content-secundario{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0;
}

@media (min-width: 1920px) {
    .newpay-card {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0;
    }

    .newpay-group-content-percentage {
        flex: 0 0 calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
        width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
        max-width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
    }

    .newpay-graph-content-card{
        flex: 0 0 calc(calc(6/ var(--ion-grid-columns, 12)) * 100%) !important;
        width: calc(calc(6/ var(--ion-grid-columns, 12)) * 100%) !important;
        max-width: calc(calc(6/ var(--ion-grid-columns, 12)) * 100%) !important;
    }

    

}

@media (max-width: 992px) {
    .newpay-graph-content-card{
        flex: 0 0 calc(calc(6 / var(--ion-grid-columns, 12)) * 100%) !important;
        width: calc(calc(6 / var(--ion-grid-columns, 12)) * 100%) !important;
        max-width: calc(calc(6 / var(--ion-grid-columns, 12)) * 100%) !important;
    }

}

@media (max-width: 520px) {
    .newpay-graph-content-card{
        flex: 0 0 calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
        width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
        max-width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
    }

    .newpay-content-secundario{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 0;
    }
}
