.main-header {
    background: var(--ion-color-primary);
    display: flex;
    align-items: center;
}

.titleHeader {
    font-size: 1.25rem;
    color: var(--ion-text-color, #000) !important;
}

.icon-header{
    color: var(--ion-color-secondary);
    font-size: 3.5rem;
}

.header-icon-select-client {
    font-size: 40px;
    color: var(--ion-color-secondary);
    cursor: pointer;
}

.header-button {
    width: fit-content;
    height: fit-content;
    margin-right: 20px;
}


@media (min-width: 1920px) {

    .main-header{
        max-height: 1.2rem;
    }

    .titleHeader{
        padding-top: 1.4rem !important;
    }

    .icon-header{
        font-size: 2.5rem;
    }

    .toolbar-container{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header-thumbnail {
        width: 2.2rem;
        height: 2.2rem;
        margin: 2.2rem 1rem 0.7rem 0.4rem;
    }

    .header-button{
        width: auto;
        height: auto;
        margin: 0 1.2rem;
        padding-top: 2rem; 
    }
}


@media (min-width: 2560px) {
    .main-header{
        max-height: 1.6rem;
    }

    .titleHeader{
        font-size: 1.6rem;
        margin-top: 0.8rem;
    }

    .icon-header{
        font-size: 3rem;
    }

    .header-icon-select-client{
        font-size: 3rem;
    }


    .header-thumbnail {
        width: 2.8rem;
        height: 2.8rem;
        margin: 2.4rem 0.8rem 0.2rem 0.6rem;
    }
    
}

@media (min-width: 3840px) {

    .main-header{
        max-height: 3.4rem;
    }

    .header-icon-select-client{
        font-size: 4.5rem;
    }

    .icon-header{
        font-size: 4.5rem;
    }

    .titleHeader{
        font-size: 2.2rem;
        padding-top: 1.6rem !important;
    }

    .header-thumbnail {
        width: 3.8rem;
        height: 3.8rem;
        margin: 2.8rem 1.2rem 0.9rem 0.6rem;
    }

    .header-button{
        margin: 0 1.4rem;
        padding-top: 1.4rem; 
    }
}


@media (max-width:767px) {

    .titleHeader {
        text-align: center;
    }

    .header-thumbnail {
        margin-right: 3px;
    }
}

@media (max-width:380px) {

    ion-item::part(native) {
        padding-left: unset;
        padding-inline-start: unset;
    }

    ion-item {
        --inner-padding-end: 16px;
        --padding-start: 0px
    }

    .titleHeader {
        font-size: 1rem;
        padding-left: 0px !important;
        padding-right: 0px !important;
        text-align: center;
    }

    .header-thumbnail {
        margin-right: 3px;
    }
}

@media (max-width:350px) {

    ion-item {
        --inner-padding-end: 7px;
        --padding-start: 0px
    }
}