.umbrals-container {
    border-radius: 16px;
}

.umbrals-card-container-header {
    display: flex;
    align-items: center;
}

.umbrals-container-title {
    font-size: 3.8vh;
    padding-bottom: 3%;
    margin: 0;
}

.umbrals-header-button {
    height: 100%;
}

.umbrals-icon-select-client {
    font-size: 4.6vh;
    color: var(--ion-color-secondary);
    cursor: pointer;
}

.grid-umbrals-layout {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.umbrals-card {
    border-radius: 10px;
    background: var(--ion-color-light);
    box-shadow: 0vh 0.4vh 0.4vh rgb(0 0 0 / 25%);
    padding-top: 2%;
    margin: 0;
    height: 15vh;
}

.umbrals-header {
    padding-top: 1%;
    padding-bottom: 0.5%;
    text-align: center;
}

.umbrals-title {
    font-size: 2.2vh;
    color: var(--ion-text-color);
}

.umbrals-content-alignment {
    text-align: center;
}

.toggle-umbrals-label {
    font-size: 1.9vh;
    color: var(--ion-color-step-550, #737373);
}

.toggle-umbrals-size {
    width: 4.7vh;
    height: 3vh;
    --handle-width: 2.5vh;
    margin-top: 4.5%;
}

@media (min-width: 2560px) {
    .umbrals-container {
        margin-top: 1.45% !important;
        border-radius: 25px;
    }

    .umbrals-card-container-header {
        height: 6.5vh;
    }

    .grid-umbrals-layout {
        grid-column-gap: 0.5vh;
        grid-row-gap: 0.5vh;
    }

    .umbrals-content-alignment {
        padding-bottom: 4%;
    }

    .toggle-umbrals-size {
        margin-top: 3.5%;
        --handle-height: 2.2vh;
        --handle-spacing: 0.2vh;
        border-radius: 50px;
    }

    .umbrals-card {
        border-radius: 20px;
    }

}

@media (min-width: 1920px) {
    .umbrals-container {
        margin-top: 2.2%;
    }

    .umbrals-container-title {
        font-size: 2.5vh;
    }

    .umbrals-icon-select-client {
        font-size: 3vh;
    }

    .grid-umbrals-layout {
        grid-template-columns: repeat(5, 1fr);
    }

    .umbrals-card {
        height: 100%;
    }

    .umbrals-title {
        font-size: 1.5vh;
    }

    .toggle-umbrals-label {
        font-size: 1.4vh;
    }

    .toggle-umbrals-size {
        width: 4.5vh;
        height: 2.7vh;
        --handle-width: 2.3vh;
    }
}

@media (max-width: 912px) {
    .umbrals-container-title {
        font-size: 2.5vh;
    }

    .umbrals-icon-select-client {
        font-size: 3.2vh;
    }

    .grid-umbrals-layout {
        grid-template-columns: repeat(3, 1fr);
    }

    .umbrals-header {
        display: grid;
        place-items: center;
        padding: 0;
        height: 4vh;
    }

    .umbrals-title {
        font-size: 1.5vh;
    }

    .toggle-umbrals-label {
        font-size: 1.4vh;
    }

    .umbrals-card {
        height: 12vh;
    }

    .toggle-umbrals-size {
        width: 4vh;
        height: 2.4vh;
        --handle-width: 2vh;
    }
}

@media (max-width: 640px) {
    .umbrals-container-title {
        font-size: 3vh;
    }

    .umbrals-icon-select-client {
        font-size: 4.2vh;
    }

    .grid-umbrals-layout {
        grid-template-columns: repeat(2, 1fr);
    }

    .toggle-umbrals-size {
        width: 5vh;
        height: 3vh;
        --handle-width: 2.5vh;
    }
}

@media (max-width: 520px) {
    .umbrals-container-title {
        font-size: 2.6vh;
    }

    .umbrals-icon-select-client {
        font-size: 3.2vh;
    }

    .grid-umbrals-layout {
        grid-template-columns: repeat(2, 1fr);
    }

    .umbrals-header {
        height: 4.6vh;
    }

    .umbrals-title {
        font-size: 1.4vh;
    }

    .toggle-umbrals-label {
        font-size: 1.3vh;
    }

    .toggle-umbrals-size {
        width: 4.2vh;
        height: 2.5vh;
        --handle-width: 2.2vh;
    }
}

@media (max-width: 320px) {
    .umbrals-card {
        height: 12vh;
        width: 14vh;
    }

    .umbrals-header {
        height: 3.5vh;
    }
}