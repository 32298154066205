/* Resolutores Cards */
.resolutor-card {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;
}

.resolutor-padding{
    padding: 0;
}

/* Card Mini */

.resolutoresMini-card {
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    background: var(--ion-color-light) !important;
    width: 30%;
}

.resolutoresCardMini-header {
    color: var(--ion-text-color, #000) !important;
    font-size: 2vh;
    min-height: 3em;
    max-height: 3.3em;
}

.resolutores-icon-mini {
    margin-top: 0.1em;
    color: var(--ion-color-success);
    padding: 0px;
    margin-right: 10px;
    font-size: 2.5vh;
}

.resolutores-icon-mini-alerted {
    margin-top: 0.1em;
    color: var(--cust-color-alerted);
    padding: 0px;
    margin-right: 10px;
    font-size: 2.5vh;
}

/* Tabla Mini*/

.resolutores-styles-title-tables {
    font-weight: 700;
    font-size: 1.5vh;
}

.resolutoresMinCard-position-row {
    text-align: center;
    font-size: 1.6vh;
    padding: 0;
}

.resolutoresMinCard-position-row-center {
    display: flex;
    justify-content: center;
    font-size: 1.6vh;
}

@media (min-width: 1920px) {

    .resolutor-card-content {
        height: fit-content;
    }

    .resolutor-card {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 0;
    }

    .resolutor-content-card {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0;
        justify-content: center;
    }

    .resolutor-group-content {
        flex: 0 0 calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
        width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
        max-width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
    }

    .resolutoresMini-card {
        width: 100%;
    }
    
    .resolutoresCardMini-header {
        min-height: 1em;
        padding: 1%;
    }
}

@media(max-width: 912px) {

    .resolutoresMini-card {
        width: 46%;
    }

    .resolutor-group-content {
        flex: 0 0 calc(calc(6 / var(--ion-grid-columns, 12)) * 100%) !important;
        width: calc(calc(6 / var(--ion-grid-columns, 12)) * 100%) !important;
        max-width: calc(calc(6 / var(--ion-grid-columns, 12)) * 100%) !important;
    }

}

@media(max-width: 820px) {

    .resolutoresCardMini-header {
        font-size: 1.5vh;
    }

    .resolutores-icon-mini {
        font-size: 1.8vh;
    }

    .resolutores-icon-mini-alerted {
        font-size: 1.8vh;
    }

    .resolutoresMinCard-position-row {
        font-size: 1.2vh;
    }

    .resolutoresMinCard-position-row-center {
        padding-top: 2%;
        font-size: 1.1vh;
    }

    .resolutoresMini-card {
        width: 45%;
    }

    .resolutores-styles-title-tables {
        font-size: 1.5vh;
    }
}

@media(max-width: 520px) {
    .resolutoresMini-card {
        width: 100%;
    }

    .resolutoresCardMini-header {
        font-size: 1.8vh !important;
    }

    .resolutores-icon-mini {
        font-size: 2.1vh;
    }

    .resolutores-icon-mini-alerted {
        font-size: 1.8vh;
    }

    .resolutores-styles-title-tables {
        font-size: 1.5vh;
    }

    .resolutoresMinCard-position-row {
        font-size: 1.5vh;
    }

    .resolutor-group-content {
        flex: 0 0 calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
        width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
        max-width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
    }

}