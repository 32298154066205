*,
*:before,
*:after {
  box-sizing: border-box;

  font-family: "Segoe UI", "Roboto", "Helvetica Neue", Arial, "Noto Sans",
    "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
}

body {
  background-color: var(--cust-body-color);
}

#styleIcon {
  color: var(--ion-color-secondary);
  margin-left: 10px;
  margin-right: 10px;
}

.ionRouterOutlet-class {
  pointer-events: unset;
  cursor: auto;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}


@media (min-width: 1920px) {

  #styleIcon {
    margin-top: 1.8rem;
  }
}

@media (min-width: 2560px) {

  #styleIcon {
    margin-top: 1.6rem;
    font-size: 2rem;
  }

}

@media (min-width: 3840px) {
  #styleIcon {
    margin-top: 1.4rem;
    font-size: 3rem;
  }
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}