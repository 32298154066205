.cards-container {
  background-color: var(--ion-color-primary);
  justify-items: center;
  border-radius: 20px;
  width: 100%;
}

.cards-container-header {
  height: 40px;
  width: -webkit-fill-available;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--cust-card-header-alerts-color);
  cursor: pointer;
}

.cards-container-header-titularidad {
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--cust-card-header-alerts-color);
  cursor: pointer;
  padding: 0;
  width: -webkit-fill-available;
}

.displayCards-icon {
  position: absolute;
  right: 1%;
  font-size: 3vh;
  padding: 0;
}

.displayCards-icon-titularidad {
  position: absolute;
  right: 1%;
  font-size: 2vh;
  padding: 0;
}

.cards-content {
  padding: 0px;
  width: -webkit-fill-available;
}

.cards-content-hidden {
  display: none;
}

.card-row {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.card-col-general {
  justify-content: center;
  display: flex;
}

.content-card {
  border-radius: 8px;
  background: var(--ion-color-light);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  margin: 0;
  width: 85%;
}

.content-card-titularidad {
  border-radius: 8px;
  background: var(--ion-color-light);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  margin: 0;
  width: 100%;
}

.summary-card-header {
  display: flex;
  height: 28px;
  padding: 0px;
  background: var(--cust-card-header-color);
  text-align: center;
  cursor: pointer;
}

.summary-card-title,
.summary-card-title-titularidad {
  font-weight: 900;
  width: 100%;
  position: absolute;
  align-self: center;
  margin: 0;
}

.card-status-icon {
  margin-top: 1.5%;
}

.card-status-icon-titularidad {
  margin-top: 3%;
}

.content-card-ion {
  line-height: 1.5;
}

.card-center {
  text-align: center;
  padding: 1%;
}

.card-text {
  font-weight: 700;
  color: var(--ion-text-color);
}

.card-col {
  justify-content: center;
  display: flex;
}

.card-text-maintenance {
  font-size: 1.7vh
}

.card-img-maintenance {
  font-size: 6vh;
  color: #EF4161;
}

.mantenimiento-card-content {
  margin: 6%;
}

@media (min-width: 3840px) {

  .card-col-general {
    flex: 0 0 calc(calc(2.4 / var(--ion-grid-columns, 12)) * 100%) !important;
    width: calc(calc(2.4 / var(--ion-grid-columns, 12)) * 100%) !important;
    max-width: calc(calc(2.4 / var(--ion-grid-columns, 12)) * 100%) !important;
  }

  .cards-container-header {
    height: 50px;
  }

  .cards-container-header-titularidad {
    padding: 0.8%;
  }

  .card-title {
    font-size: 2vh;
  }

  .displayCards-icon {
    font-size: 2.5vh;
  }

  .summary-card-title {
    font-size: 1.4vh;
  }

  .summary-card-header {
    height: 60px;
  }

  .card-status-icon {
    font-size: 2.5vh;
    margin-top: 0.5%;
  }

  .card-status-icon-titularidad {
    font-size: 2.5vh;
    margin-top: 0.5%;
  }

  .card-title-titularidad {
    font-size: 1.8vh;
  }

  .card-text {
    font-size: 1.5vh;
  }

  .card-text-minutes {
    font-size: 1.4vh;
  }

  .card-text-alternative {
    font-size: 1.3vh;
  }
  
  .summary-card-title-titularidad {
    font-size: 1.3vh;
}

}

@media (max-width: 2560px) {

  .cards-container-header-titularidad {
    padding: 0.8%;
  }

  .card-status-icon {
    font-size: 2.5vh;
    margin-top: 0.5%;
  }

  .card-status-icon-titularidad {
    font-size: 2.5vh;
    margin-top: 0%;
  }

  .summary-card-header {
    height: 40px;
  }

  .card-title {
    font-size: 2vh;
  }

  .summary-card-title {
    font-size: 1.7vh;
  }

  .card-text {
    font-size: 1.6vh;
  }

  .card-text-minutes {
    font-size: 1.4vh;
  }

  .card-text-alternative {
    font-size: 1.3vh;
  }

}

@media (max-width: 1920px) {

  .cards-container-header-titularidad {
    padding: 1%;
  }

  .card-status-icon {
    font-size: 2.5vh;
    margin-top: 0%;
  }

  .card-status-icon-titularidad {
    font-size: 2.5vh;
    margin-top: 0%;
  }

  .summary-card-header {
    height: 28px;
  }

  .card-title {
    font-size: 2vh;
  }

  .summary-card-title {
    font-size: 1.5vh;
  }

  .summary-card-title-titularidad{
    font-size: 1.5vh;
  }

  .card-text-alternative {
    font-size: 1.4vh;
  }

  .card-text-minutes {
    font-size: 1.5vh;
  }
}

@media (max-width: 1800px) {

  .summary-card-title {
    font-size: 1.7vh;
  }

  .summary-card-title-titularidad {
    font-size: 1.7vh;
  }

  .card-status-icon {
    font-size: 3.4vh;
    margin-top: 1.5%;
  }

  .card-status-icon-titularidad {
    font-size: 3vh;
    margin-top: 1.5%;
  }

  .card-text {
    font-size: 1.9vh;
  }

  .card-text-minutes {
    font-size: 1.8vh;
  }

  .card-text-alternative {
    font-size: 1.6vh;
  }

  .card-title {
    font-size: 2.5vh;
  }

  .card-title-titularidad {
    font-size: 2vh;
  }
}

@media(max-width: 820px) {

  .content-card {
    width: 100%;
  }

  .card-title {
    font-size: 2vh;
  }

  .card-title-titularidad {
    font-size: 1.5vh;
  }

  .summary-card-title {
    font-size: 1.3vh;
    font-weight: 600;
  }

  .summary-card-title-titularidad {
    font-size: 1vh;
  }

  .card-status-icon {
    font-size: 1.8vh;
    margin-top: 2%;
  }

  .card-status-icon-titularidad {
    font-size: 1vh;
    margin-top: 3%;
  }

  .card-text {
    font-size: 1.3vh;
    font-weight: 500;
  }

  .card-text-minutes {
    font-size: 1.2vh;
  }

  .card-text-alternative {
    font-size: 1.2vh;
  }

}

@media (max-width: 770px) {
  .card-status-icon-titularidad {
    font-size: 1.5vh;
    margin-top: 3%;
  }
}

@media(max-width: 520px) {

  .cards-container-header {
    height: 5%;
  }

  .content-card-ion {
    line-height: 1.2;
  }

  .card-title {
    font-size: 2vh;
  }

  .card-title-titularidad {
    font-size: 1.5vh;
  }

  .cards-container {
    margin: 1%;
  }

  .card-row {
    padding: 0;
  }

  .content-card {
    width: 100%;
    border-radius: 5px;
  }

  .summary-card-header {
    height: 16px;
  }

  .content-card-ion {
    padding: 2%;
  }

  .card-status-icon {
    font-size: 1.5vh;
    margin-top: 0.5%;
  }

  .card-status-icon-titularidad {
    font-size: 1.2vh;
    margin-top: 1.5%;
  }

  .summary-card-title {
    font-size: 1.3vh;
    font-weight: normal;
  }

  .summary-card-title-titularidad {
    font-size: 1vh;
    font-weight: normal;
  }

  .card-text {
    font-size: 1.3vh;
    font-weight: normal;
  }

  .card-text-minutes {
    font-size: 1.2vh;
  }

  .card-text-alternative {
    font-size: 1.2vh;
  }

  .card-text-maintenance {
    font-size: 1.3vh;
  }

  .card-img-maintenance {
    font-size: 5vh;
  }

}