/* Overall Style */
.undefined {
    color: var(--ion-color-step-750);
}

.decidir-container {
    display: flex;
}

.decidir-card {
    background: var(--ion-background-color);
    height: 100%;
}

.decidir-space {
    margin-bottom: 2%;
}

.decidir-group {
    text-align: center;
    font-size: 1.8vh;
    color: var(--ion-text-color);
    margin: 0;
    padding: 0 0 0 6%;
}

.decidir-graphics-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0;
}

.decidir-graphics-content-hidden {
    display: none;
}

.content-evolution {
    padding-top: 2% !important;
    width: 100%;
}

/* Prisma Style */

.decidir-align {
    height: 100%;
    display: flex;
    align-items: center;
}

.decidir-align-row {
    width: 100%;
}

.decidir-padding {
    padding: 0;
}

.decidir-persistor {
    background-color: var(--ion-color-success);
    color: white;
    font-size: 2vh;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 1%;
    height: 100%;
    padding: 0;
    margin: 0;
}

.decidir-persistor-alerted {
    background-color: var(--cust-color-alerted);
    color: white;
    font-size: 2vh;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 1%;
    height: 100%;
    padding: 0;
    margin: 0;
}

.decidir-styles-tables {
    color: var(--ion-text-color, #000);
    text-align: center;
    font-size: 2vh;
}

.decidir-text-percentage {
    font-size: 3.5vh;
    font-weight: 600;
}

.decidir-text-percentage-cards {
    font-size: 3vh;
}

.decidir-styles-title-tables {
    font-weight: 700;
}

.decidir-styles-rows {
    border-bottom: 1px solid var(--ion-color-step-300);
}

/* Rechazos Style */

.decidir-styles-card {
    text-align: center;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 25%);
    background: var(--ion-color-light);
}

.decidir-margin-card {
    margin: 1em 2em 1em;
}

/* Graphics Style */

.decidir-graphics {
    padding: 2%;
}

/* Cards Style */

.decidir-styles-card-header {
    padding: 2%;
}

.decidir-icon {
    color: var(--ion-color-success);
    padding: 0px;
    margin-right: 1%;
    font-size: 2.5vh;
}

.decidir-icon-alerted {
    color: var(--cust-color-alerted);
    padding: 0px;
    margin-right: 1%;
    font-size: 2.5vh;
}

.decidir-header-title {
    color: var(--ion-text-color, #000);
    font-size: 1.9vh;
    padding: 0px;
}

.decidir-timer-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* GRID */

.decidir-card-decidir {
    height: 94.4%;
}

.totalesDecidir {
    grid-column: totalesDecidir;
}

.cardGraphicEvolution {
    grid-column: cardGraphicEvolution;
}

.cardResolutores {
    grid-column: cardResolutores;
    width: 99%;
}

.cardComercios {
    grid-column: cardComercios;
    width: 99%;
}

.cardMediosdePago {
    grid-column: cardMediosdePago;
    width: 99%;
}

.cardGraphicsMediosDePago {
    grid-column: cardGraphicsMediosDePago;
    width: 99%;
}

.cardGraphicsComercios {
    grid-column: cardGraphicsComercios;
    width: 99%;
}

.messagesPrisma {
    grid-column: messagesPrisma;
}

.decidir-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, auto);
    grid-template-areas:
        "messagesPrisma            messagesPrisma"
        "totalesDecidir           cardGraphicEvolution"
        "cardResolutores          cardResolutores"
        "cardComercios            cardComercios"
        "cardMediosdePago         cardMediosdePago"
        "cardGraphicsMediosDePago cardGraphicsMediosDePago"
        "cardGraphicsComercios    cardGraphicsComercios";
}

.decidir-card,
.decidir-card-decidir {
    margin-right: 0;
}

.decidir-categories-grid-resolutores,
.decidir-categories-grid-comercios,
.decidir-categories-grid-mp-cm {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

/* Media query */

@media (min-width: 3840px) {

    .decidir-icon, 
    .decidir-icon-alerted {
        font-size: 2.3vh !important;
    }

    .decidir-persistor {
        font-size: 1.7vh;
    }

}

@media (min-width: 2560px) {

    .decidir-text-percentage,
    .decidir-text-percentage-cards {
        font-size: 2.2vh;
    }

    .decidir-card-decidir,
    .decidir-evolucion {
        height: 100%;
    }

    .decidir-categories-grid-mp-cm {
        grid-template-columns: repeat(5, 1fr);
    }

    .decidir-persistor {
        font-size: 1.4vh;
    }

    .decidir-group {
        padding: 0 0 0 6%;
    }

}

@media (min-width: 1920px) {

    .decidir-container {
        margin-top: 2rem;
    }

    .decidir-group {
        font-size: 1.4vh;
        padding: 0;
    }

    .decidir-icon,
    .decidir-icon-alerted {
        font-size: 2.2vh;
    }

    .decidir-persistor {

        font-size: 1.7vh;
    }

    .decidir-graphics-content {
        grid-template-columns: repeat(4, 1fr);
    }

    .decidir-header-title {
        font-size: 1.5vh;
    }

    .decidir-text-percentage,
    .decidir-text-percentage-cards {
        font-size: 3vh;
    }

    .decidir-categories-grid-mp-cm {
        grid-template-columns: repeat(5, 1fr);
    }

    .decidir-categories-grid-comercios {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    .decidir-styles-card {
        margin: 0.2rem;
    }

    .decidir-container {
        display: grid;
        grid-template-columns: 2.2fr repeat(3, 1fr);
        grid-template-rows: repeat(4, auto);
        grid-template-areas:
            "messagesPrisma            messagesPrisma           messagesPrisma           messagesPrisma"
            "totalesDecidir           cardGraphicEvolution     cardGraphicEvolution     cardGraphicEvolution"
            "cardResolutores          cardComercios            cardComercios            cardComercios"
            "cardMediosdePago         cardMediosdePago         cardMediosdePago         cardMediosdePago"
            "cardGraphicsMediosDePago cardGraphicsMediosDePago cardGraphicsMediosDePago cardGraphicsMediosDePago"
            "cardGraphicsComercios    cardGraphicsComercios    cardGraphicsComercios    cardGraphicsComercios";
    }

    .decidir-categories-grid-resolutores {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .decidir-card-decidir,
    .decidir-evolucion {
        height: 100%;
    }

    .cardResolutores {
        width: 100%;
    }

    .cardMediosdePago, .cardGraphicsMediosDePago, .cardGraphicsComercios {
        grid-column: cardMediosdePago;
        width: 99.5%;
    }

}

@media (max-width: 1220px) {

    .decidir-text-percentage {
        font-size: 3.5vh;
    }

    .decidir-text-percentage-cards {
        font-size: 3.5vh;
    }

    .decidir-categories-grid-mp-cm {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

}

@media (max-width: 966px) {

    .decidir-container {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas:
            "messagesPrisma"
            "totalesDecidir"
            "cardGraphicEvolution"
            "cardResolutores"
            "cardComercios"
            "cardMediosdePago"
            "cardGraphicsMediosDePago"
            "cardGraphicsComercios";
    }

    .decidir-icon,
    .decidir-icon-alerted {
        font-size: 2.3vh;
    }

    .decidir-text-percentage {
        font-size: 2.3vh;
    }

    .decidir-text-percentage-cards {
        font-size: 2.5vh;
    }

    .decidir-group {
        font-size: 1.6vh;
        padding: 0 6%;
    }

    .decidir-styles-card {
        margin: 1%;
    }

    .decidir-header-title {
        font-size: 1.6vh;
    }

    .decidir-categories-grid-resolutores,
    .decidir-categories-grid-comercios,
    .decidir-categories-grid-mp-cm {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }

    .totalesDecidir, .cardResolutores, .cardComercios, .cardMediosdePago, .cardGraphicsMediosDePago, .cardGraphicsComercios {
        width: 98%;
    }

}

@media (max-width: 650px) {

    .decidir-icon,
    .decidir-icon-alerted {
        font-size: 2.2vh;
    }

    .decidir-group {
        font-size: 1.4vh;
        min-width: 50%;
    }

    .decidir-categories-grid-resolutores,
    .decidir-categories-grid-comercios,
    .decidir-categories-grid-mp-cm {
        display: grid;
        grid-template-columns: 1fr;
        padding-right: 0;
        padding-left: 0;
    }

    .decidir-card-decidir,
    .decidir-card {
        margin-right: 0.6rem;
    }

    .decidir-graphics-content {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 0;
    }

}

@media (max-width: 520px) {

    .decidir-persistor {
        font-size: 1.5vh;
    }

    .totalesDecidir {
        padding: 0;
    }

    .cardGraphicEvolution {
        padding: 0;
    }

    .decidir-styles-header {
        padding: 2%;
    }

    .decidir-group {
        padding: 2%;
    }

    .totalesDecidir, .cardResolutores, .cardComercios, .cardMediosdePago, .cardGraphicsMediosDePago, .cardGraphicsComercios {
        width: 100%;
    }

}