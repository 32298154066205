.content-card-user-general {
    padding: 0;
}

.content-card-general {
    flex: 0 0 calc(calc(2.5 / var(--ion-grid-columns, 12)) * 100%);
    width: calc(calc(2.5 / var(--ion-grid-columns, 12)) * 100%);
    max-width: calc(calc(2.5 / var(--ion-grid-columns, 12)) * 100%);
}

.user-card-header {
    background: var(--cust-card-header-alerts-color);
    color: var(--ion-text-color, #000);
    font-size: 2.2vh;
    padding: 1vh;
    font-weight: 600;
}

.user-hidden {
    display: none;
}

.display-user-icon {
    position: absolute;
    right: 10px;
    font-size: 3vh;
    padding: 0;
}

/* CANVAS */

.user-content-canvas-graphic {
    width: 12rem;
}

.user-content-canvas-legend ul {
    padding-left: 3%;
}

.user-content-canvas-legend ul li {
    color: var(--ion-text-color, #000);
    list-style-type: none;
    font-size: 2vh;
    font-weight: 450;
    margin: 0;
    padding: 0;
}

.user-content-canvas-legend ul li span {
    width: 2vh;
    height: 2vh;
    display: inline-block;
    border-radius: 3px;
}

.user-content-canvas-graphics {
    /* display: flex;
    align-items: center; */
    width: 6.5rem;
}

/* TABLE */

.user-table-card {
    text-align: center;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 25%);
    background: var(--ion-color-light);
}

.user-table-tables {
    color: var(--ion-text-color, #000);
    text-align: center;
    font-size: 2vh;
}

.user-table-title-tables {
    font-weight: 700;
}

.user-table-rows {
    border-bottom: 1px solid var(--ion-color-step-300);
}

.user-table-margin-card {
    margin: 1em 2em 1em;
}

.user-table-button {
    margin-right: 20px;
}

.user-table-select-client {
    font-size: 5vh;
    color: var(--ion-color-secondary);
    cursor: pointer;
}

.user-table-client h5 {
    font-size: 2.5vh;
    margin: 0;
    padding-left: 1%;
}

/* USER SPECIFY */

.user-card-header-specify {
    background: var(--cust-card-header-alerts-color);
    color: var(--ion-text-color, #000) !important;
    font-size: 2vh;
    padding: 0.5vh;
    font-weight: 500;
}

.user-content-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1;
}

.user-title-graphic {
    color: var(--ion-text-color, #000) !important;
    font-size: 2vh;
    font-weight: 400;
}

.content-specify-card {
    background: var(--ion-color-light);
}

.user-legends {
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 25%);
    background: var(--ion-background-color);
}

.content-NoUser-card {
    background: var(--ion-color-light);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-NoUser {
    background-color: var(--ion-background-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1vh;
}

.user-NoUser-internal {
    font-size: 2vh;
    font-weight: 600;
    text-align: center;
}

.user-NoUser-icon {
    font-size: 8vh;
    padding: 2vh;
}


@media (min-width: 3840px) {
    
    .user-content-cards {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }

    .user-content-canvas-graphics {
        width: 13rem;
    }

    .content-NoUser-card {
        background: var(--ion-color-light);
        height: 82%;
    }

    .content-user-statistics {
        margin-top: 1%;
    }

    .user-NoUser-icon {
        font-size: 6vh;
        padding: 1vh;
    }

    .user-general {
        font-size: 1.8vh;
    }

    .user-table-select-client {
        font-size: 2vh;
    }

    .user-table-client h5 {
        font-size: 2vh;
        padding-left: 0;
    }

}

@media ( max-width: 2560px) {

    .content-NoUser-card {
        background: var(--ion-color-light);
        height: 82%;
    }

    .content-user-statistics {
        margin-top: 1%;
    }

    .user-NoUser-icon {
        font-size: 6vh;
        padding: 1vh;
    }

    .user-general {
        font-size: 1.8vh;
    }

    .user-table-select-client {
        font-size: 3vh;
    }

    .user-table-client h5 {
        font-size: 2vh;
        padding-left: 1%;
    }

    .user-content-canvas-graphics {
        width: 9rem;
    }

}

@media (max-width: 1920px) {

    .content-user-statistics {
        margin-top: 1%;
    }

    .user-NoUser-icon {
        font-size: 6vh;
        padding: 1vh;
    }

    .user-general {
        font-size: 1.8vh;
    }

    .content-NoUser-card {
        height: 89%;
    }

    .user-table-select-client {
        font-size: 4vh;
    }
}

@media (max-width: 1800px) {

    .user-content-canvas-graphics {
        width: 5.5rem;
    }

}

@media (max-width: 992px) {

    .content-card-general {
        flex: 0 0 calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
        width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
        max-width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
    }

    .user-general {
        font-size: 2vh;
    }

    .mobile-hidden {
        display: none;
    }

    .user-content-cards {
        grid-template-columns: repeat(1, 1fr);

    }

    .user-NoUser {
        margin-bottom: 6%;
    }

    .user-table-select-client {
        font-size: 4.5vh;
    }

    .user-table-client h5 {
        font-size: 2vh;
        padding-left: 7%;
    }

    .user-content-canvas-graphics {
        width: 8rem;
    }
}

@media(max-width: 520px) {

    .user-NoUser-icon {
        font-size: 6vh;
        padding: 0;
    }

    .user-table-client h5 {
        padding-left: 0;
    }

    .user-table-tables {
        font-size: 1.7vh;
    }

    .user-table-margin-card {
        margin: 0;
    }


}