.card-true {
  background-color: var(--ion-color-secondary);
  cursor: pointer;
  color: white;
}

.card-false {
  background-color: var(--ion-color-tertiary);
  cursor: pointer;
  color: white;
}

.messages-without-scroll {
  margin: 0px 5px 0px 5px;
}

.messages-with-scroll {
  z-index: 1;
}

.card-scroll {
  max-height: 1.5rem;
  margin: 0px;
}

.card-no-scroll {
  max-height: fit-content;
  margin: 0px;
}

.message-prisma-col {
  padding-right: 0px;
}

.message-prisma-close-icon {
  font-size: 20px;
  cursor: pointer;
  padding-top: 10px;
}

.message-row-closed {
  display: none;
}

.messange{
  padding-bottom: 1.5rem;
}

.display-icon-chevron {
  position: absolute;
  right: 1%;
  font-size: 3vh;
  padding: 0;
}


@media (min-width: 1920px) {
  .card-scroll{
    max-height: 1.8rem;
  }

  .margin-card{
    margin-top: 1rem
  }
  
}

@media (min-width: 2560px){
  .messange{
    font-size: 1rem;
  }

  .card-scroll{
    max-height: 1.8rem;
  }

  .display-icon-chevron{
    font-size: 2vh;
  }
}

@media (min-width: 3840px){
  .card-scroll{
    max-height: 2.6rem;
  }
  .messange{
    font-size: 1.5rem;
  }
}