.footer {
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background: var(--ion-color-primary);
  color: var(--ion-text-color, #000) !important;
  font-size: x-small;
}

.footerLogin {
  position: fixed;
}

/* Deskop */

@media (min-width: 992px) {

  .footer {
    font-size: medium;
  }
}

@media (min-width: 1920px) {

  .footerText{
    margin: 0.5rem;
  }

}

@media (min-width: 2560px){
  .footerText{
    margin: 0.8rem;
    font-size: 1.2rem;
  }
}

@media (min-width: 3840px){
  .footerText{
    margin: 1.2rem;
    font-size: 1.7rem;
  }

}
