.maintenance-container {
    align-items: center;
}

.maintenance-header {
    position: absolute;
    background-color: var(--ion-color-primary);
    padding: 0;
    margin: 0;
}

.maintenance-img-logo {
    height: 6em;
}

.maintenance-card {
    margin-top: 10%;
    width: 50%;
}

.maintenance-card-header {
    text-align: center;
}

.maintenance-card-icon {
    font-size: 10em;
    color: #EF4161;
}

.maintenance-card-text {
    text-align: center;
    font-size: x-large;
}

.maintenance-button {
    color: var(--cust-button-color);
    --border-color: var(--cust-button-color);
    --border-radius: 12px;
}

.maintenance-button:hover {
    border-radius: 12px;
    --background: var(--cust-button-color);
    color: white;
}

.maintenance-footer {
    width: 100%;
}

@media (max-width:1280px) {

    .maintenance-card {
        margin-top: 11%;
    }
}

@media (max-width:820px) {

    .maintenance-card {
        margin-top: 20%;
        width: 70%;
    }
}

@media (max-width:600px) {

    .maintenance-card {
        margin-top: 35%;
        width: 90%;
    }
}

@media (max-width:520px) {

    .maintenance-card {
        margin-top: 35%;
        width: 70%;
    }

    .maintenance-card-icon {
        font-size: 5em;
    }

    .maintenance-card-text {
        font-size: large;
    }
}