.ABMUsersContainerTitle {
    font-size: 30px;
}

.ABMUsersTable {
    border-radius: 10px;
    background: var(--ion-color-primary);
}

.ABMUsersGrid {
    box-shadow: 0px 2px 2px 0px;
    border-radius: 5px;
}

.ABMUsersGrid ion-row {
    border-bottom: 1px solid var(--ion-color-step-500);
}

.ABMUsersCol {
    text-align: center;
    color: var(--ion-color-step-950);
}

.ABMUsersColTitle {
    font-weight: bold;
}

.ABMUsersCardTable {
    background: var(--ion-color-light);
    border-radius: 0.625rem;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

.ABMUsersTableButton {
    zoom: 1.2;
}

.AMBUsersButton {
    font-size: 300%;
}

.ABMUsersFormCard {
    width: 80%;
    margin: auto;
    margin-bottom: 15px;
}

.ABMUsersFormTitle {
    color: var(--ion-text-color);
    margin-top: 20px;
}

.ABMUsersFormCol {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.ABMUsersFormLabel {
    color: var(--ion-color-step-950);
    font-weight: bold;
}

.ABMUsersFormItem {
    border: 1px solid #b7bec2;
    border-radius: 15px;
    padding: 0px !important;
}

.ABMUsersFormInput {
    font-size: 14px;
    border-radius: 4px;
    color: var(--ion-color-step-950);
}

.ABMUsersTooltipLabel {
    display: flex;
    align-items: center;
}

.ABMUsersTooltip {
    margin-left: 5px;
    cursor: pointer;
}

.ABMUsersTooltipText {
    font-size: 10px;
    font-weight: 200;
    background-color: var(--ion-color-light);
    padding: 2px 5px;
    margin-left: 2px;
    border-radius: 2px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    display: none;
}

.ABMUsersTooltip:hover+.ABMUsersTooltipText {
    display: block;
}


.ABMUsersHeaderIconAddUser {
    font-size: 30px !important;
}


.margin-top {
    margin-top: 1.6rem;
}

.margin-container-button-delete {
    width: auto;
    height: auto;
}

.AMBUsersButton {
    font-size: 2.4rem;
} 

.icon-deleteUser {
    font-size: 2.8rem;
    padding: 1% 2% 0% 2%;
    color: var(--cust-color-alerted)
}


.ABMUsers-container-button * {
    height: 4rem;
    margin-bottom: 0.5rem;
}



@media (min-width: 2560px) {
    .ABMUsersCol {
        font-size: 1.4rem;
    }

    .ABMUsersContainerTitle {
        font-size: 1.8rem;
    }

    .ABMUsersFormLabel {
        font-size: 1.2rem;
    }

    .ABMUsersFormInput {
        font-size: 1.1rem;
    }

    .ABMUsersFormTitle {
        font-size: 1.8rem;
    }

    .AMBUsersButton {
        font-size: 3rem;
    } 
    
    .icon-deleteUser {
        font-size: 2.8rem;
    }
    
    
    .ABMUsers-container-button * {
        height: 3.5rem;
        margin: 0;
    }

    
}


@media (min-width: 3840px) {

    .container-add-user{
        display: flex;
        justify-content: center;
    }

    .client-icon{
        height: 4rem;
    }
    .client-icon > * {
        font-size: 3rem !important;
    }

    .AMBUsersButton {
        font-size: 5rem;
    } 
    
    .icon-deleteUser {
        font-size: 4.8rem;
    }
    
    .ABMUsersTableButton{
        zoom: 1.6
    }
    
    .ABMUsers-container-button * {
        height: 5rem;
        margin: 0;
    }


    .ABMUsersCol {
        font-size: 2rem;
    }

    .ABMUsersContainerTitle {
        font-size: 2.9rem;
    }

    .ABMUsersFormLabel {
        font-size: 1.8rem;
    }

    .ABMUsersFormInput {
        font-size: 1.7rem;
    }

    .ABMUsersFormTitle {
        font-size: 2.6rem;
    }
}


@media (min-width: 1920px) {
    .ABMUsersTable {
        margin-top: 2.2rem;
    }

    .client-select-icon {
        padding: 0 !important;
    }

    .ABMUsersFormCard {
        margin-top: 1.8rem;
    }
}


@media(max-width: 840px) {
    .hidden-col{
        display: none;
    }

    .ABMUsersRow{   
        padding: 0 1rem;
        font-size: 1rem;
    }

    .ABMUsersTableButton{
        zoom: 1
    }
}


@media (max-width: 640px) {

    .ABMUsersRow{
        padding: 0;
    }

    .AMBUsersButton {
        font-size: 2rem;
    } 
    
    .icon-deleteUser {
        font-size: 1.8rem;
    }
    
    .ABMUsers-container-button * {
        height: 2.5rem;
        margin: 0;
    }

    .ABMUsersReducedTableMobile-hidden {
        display: none;
    }

    .ABMUsersContainerTitle {
        font-size: 1.4em;
    }

    .header-icon-select-client {
        font-size: 1.4em !important;
    }

    .ABMUsersHeaderIconAddUser {
        font-size: 1.3em !important;
    }
}


@media (max-width: 520px) {

    .ABMUsersTableMobile-hidden {
        display: none;
    }

    .ABMUsersContainerTitle {
        font-size: 0.7em;
    }

    .header-icon-select-client {
        font-size: 1.4em !important;
    }

    .ABMUsersHeaderIconAddUser {
        font-size: 1.3em !important;
    }

}