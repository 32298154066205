.reports-list-header {
  height: 3vh;
  width: -webkit-fill-available;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--cust-card-header-alerts-color);
}

.card-content-reports{
  width: -webkit-fill-available;
}

.main-reports-card {
  height: 100%;
  border-radius: 20px;
  background-color: var(--ion-color-primary);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  justify-items: center;
  padding-top: 0px;
  width: 100%;
}

.report-card {
  align-items: center;
  width: 100%;
  margin: 0px;
  background: var(--ion-color-light);
  cursor: pointer;
}

.alert-card-content {
  height: 10vh;
  text-align: center;
  transition: height 0.5s linear;
}

.alert-card-content-active {
  text-align: center;
  transition: height 0.3s linear;
}

.alert-timestamp {
  font-weight: bolder;
  font-size: 2vh;
  padding: 0.8%;
  color: white;
  background-color: var(--ion-color-secondary);
  border-radius: 10px;
  display: inline-flex;
}

.alert-name {
  font-weight: bold;
  font-size: 1.8vh;
  padding: 2%;
  display: block;
  color: var(--ion-text-color);
}

.graph-card {
  margin: 0px;
}

@media (min-width: 3840px) {

  .reports-list-title {
    font-size: 2vh;
  }

  .alert-name {
    font-size: 1.7vh;
  }

  .alert-timestamp {
    margin: 1.5%;
  }

}

@media (max-width: 2560px) {
  
  .reports-list-title {
    font-size: 2vh;
  }

  .alert-card-content {
    height: 8.5vh;
  }

  

}

@media (max-width: 1920px) {

  .reports-list-title {
    font-size: 2vh;
  }

  .alert-card-content {
    height: 8.5vh;
  }

}

@media (max-width: 1800px) {

  .reports-list-title {
    font-size: 2.5vh;
  }

  .alert-card-content {
    height: 10vh;
  }

}

@media(max-width: 820px) {

  .reports-list-title {
    font-size: 2vh;
  }

  .alert-card-content {
    height: 9.5vh;
  }

  .alert-name {
    font-size: 1.5vh;
    margin-bottom: 8%;
  }

}

@media(max-width: 520px) {

  .alert-card-content {
    height: 10vh;
  }

}