@media (min-width: 3840px) {

    .banks-timer{
        padding-right: 1.4rem;
    }

    .value{
        font-size: 1.4rem;
    }

    .banks-timer{
        padding-bottom: 0.5rem;
        margin-top: 0.4rem;
    }
    
}

@media (min-width: 2560px) {
    .banks-timer{
        padding-top: 2rem;
    }
    .value{
        font-size: 1rem;
    }

    .banks-timer{
        padding-bottom: 0.5rem;
        margin-top: 0.6rem;
    }
}

@media (min-width: 1920px) {
    .banks-timer{
        padding-top: 1.8rem;
        padding-right: 0.5rem;
    }
    .value{
        font-size: 0.8rem;
    }
}